import Images from "../assets/images";
import { useEffect, useRef, useState } from "react";
import { statusWiseOnboardingRedirectToPage, isUserExchangeStatusHasPermission, isUserStatusHasPermission, isNotNullUndefinedBlank, isNotNullUndefined } from "../helpers/common";
import { useNavigate } from "react-router-dom";
import { storage } from "../utils/storage";
import { useGetDirectIndirectReferral, useGetMostGainedUsers, useGetReferralEarning, useGetTopAssets, useGetExchangeAssets, useGetTopHoldings } from "../services/exchange.service";
import IResponse from "../types/response";
import * as Sentry from "@sentry/react";
import { formatNumberWithCommas } from "../helpers/commaformattednumber";
import { IProfitAndloss, ITopAssets } from "../types/profitloss";
import { IDirectIndirectReferral, IReferralEarning } from "../types/referral";
import { IMostGainedUsers } from "../types/user";
import { validateNotEmpty } from '../utils/validation';
import { webSocketUrl } from "../helpers/config";

const Dashboard = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [topHoldings, setTopHoldings] = useState<IProfitAndloss[]>([]);

    const pageNumber = useRef<number>(1);
    const pageSize = useRef<number>(5);
    const limit = useRef<number>(3);
    const recordCount = useRef<number>(0);

    const [mostGainedUser, setMostGainedUser] = useState<IMostGainedUsers[]>([]);
    const directIndirectReferral = useRef<IDirectIndirectReferral>(JSON.parse('{}'));
    const referralEarning = useRef<IReferralEarning>(JSON.parse('{}'));

    const [topAssets, setTopAssets] = useState<ITopAssets[]>([]);
    const [topPerformerFilterType, setTopPerformerFilterType] = useState('Today');
    const topPerformerFilterName = useRef('Today');
    const [periodicalTypeFilterType, setperiodicalTypeFilterType] = useState('Daily');
    const periodicalTypeFilterName = useRef('Daily');

    const userId = storage.getUserId() ?? "";
    const userExchangeAccountId = storage.getUserExchangeAccountId() ?? "";

    const [profitModel, setProfitModel] = useState<any>({
        todayProfit: 0,
        totalProfit: 0,
        totalProfitPercentage: 0,
        floatingValue: 0,
        totalFloatingValuePercentage: 0,
        availableFuel: 0
    });

    const handleTopPerformerFilterChange = (event: any) => {
        topPerformerFilterName.current = event.target.options[event.target.selectedIndex].text;
        setTopPerformerFilterType(event.target.value);
    };

    const handlePeriodicalTypeFilterChange = (event: any) => {
        periodicalTypeFilterName.current = event.target.options[event.target.selectedIndex].text;
        setperiodicalTypeFilterType(event.target.value);
    }

    const getReferralEarning = useGetReferralEarning({
        onSuccess: (response: IResponse) => {
            if (response.data.length > 0) {
                referralEarning.current = response.data[0];
            }
            setLoading(false);
        },
        onError: (error: IResponse) => {
            setLoading(false);
            Sentry.captureException(error);
            console.error("Error fetching Referral Earning:", error);
        }
    });

    const getMostGainedUsers = useGetMostGainedUsers({
        onSuccess: (response: IResponse) => {
            if (response.data.topGainedUsers.length > 0) {
                response.data.topGainedUsers.forEach((x: any) => {
                    x.fullName = (isNotNullUndefinedBlank(x.fullName)) ? x.fullName : x.username
                });
                setMostGainedUser(response.data.topGainedUsers);
                recordCount.current = response.recordCount;
            }
        },
        onError: (error: IResponse) => {
            setLoading(false);
            Sentry.captureException(error);
            console.error("Error fetching Most Gained-Users:", error);
        }
    });

    const getDirectIndirectReferral = useGetDirectIndirectReferral({
        onSuccess: (response: IResponse) => {
            if (isNotNullUndefined(response.data)) {
                const directIndirectReferralModel: IDirectIndirectReferral = {
                    username: storage.getUserFullName() ?? "",
                    referralCode: storage.getUserReferrerCode() ?? "",
                    directReferrals: isNotNullUndefined(response.data.directReferrals) ? response.data.directReferrals : 0,
                    indirectReferrals: isNotNullUndefined(response.data.indirectReferrals) ? response.data.indirectReferrals : 0
                };
                directIndirectReferral.current = directIndirectReferralModel;
            }
            setLoading(false);
        },
        onError: (error: IResponse) => {
            setLoading(false);
            Sentry.captureException(error);
            console.error("Error fetching Direct-Indirect Referral:", error);
        }
    });

    const getTopHoldingAssets = useGetTopHoldings({
        onSuccess: (response: IResponse) => {
            if (response.data.length > 0) {
                const exchangeAssetsString = storage.getExchangeAssetsList();
                const exchangeAssetsList = exchangeAssetsString ? JSON.parse(exchangeAssetsString) : [];
                const assets = response.data.map((a: { assetId: any }) => {
                    const setAsset = exchangeAssetsList.data.filter(
                        (localAsset: { assetId: any }) => localAsset.assetId === a.assetId
                    )[0];

                    return {
                        ...a,
                        assetSymbol: setAsset?.assetSymbol || '',
                        assetName: setAsset?.assetName || '',
                        assetLogoUrl: setAsset?.assetLogoUrl || '',
                    };
                });
                setTopHoldings(assets);
            } else {
                setTopHoldings(response.data);
            }
        },
        onError: (error: IResponse) => {
            setLoading(false);
            Sentry.captureException(error);
            console.error("Error fetching top holdings:", error);
        }
    });

    const getHoldings = async () => {
        const userExchangeAccountId = storage.getUserExchangeAccountId() ?? "";
        getTopHoldingAssets.mutate({
            "userExchangeAccountId": userExchangeAccountId
        });
    };

    const getTopAssets = useGetTopAssets({
        onSuccess: (response: IResponse) => {
            if (response.data.length > 0) {
                const exchangeAssetsString = storage.getExchangeAssetsList();
                const exchangeAssetsList = exchangeAssetsString ? JSON.parse(exchangeAssetsString) : [];
                const assets = response.data.map((a: { assetId: any }) => {
                    const setAsset = exchangeAssetsList.data.filter(
                        (localAsset: { assetId: any }) => localAsset.assetId === a.assetId
                    )[0];

                    return {
                        ...a,
                        assetSymbol: setAsset?.assetSymbol || '',
                        assetName: setAsset?.assetName || '',
                        assetLogoUrl: setAsset?.assetLogoUrl || '',
                    };
                });
                setTopAssets(assets);
            } else {
                setTopAssets(response.data);
            }
            getHoldings();
        },
        onError: (error: IResponse) => {
            setLoading(false);
            Sentry.captureException(error);
            console.error("Error fetching Most Gained-Users:", error);
        }
    });

    const onGetExchangeAssets = async () => {
        if (isNotNullUndefinedBlank(storage.getToken())) {
            getExchangeAssets.mutate(1);
        }
    };

    const getExchangeAssets = useGetExchangeAssets({
        onSuccess: (response: IResponse) => {
            if (isNotNullUndefined(response)) {
                const exchangeAssetsList = response;
                storage.setExchangeAssetsList(JSON.stringify(exchangeAssetsList));
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    // WebSocket 
    useEffect(() => {
        if (validateNotEmpty(userExchangeAccountId)) {
            // Initialize WebSocket connection
            const webSocket = new WebSocket(webSocketUrl("stream?userExchangeAccountId=" + userExchangeAccountId));

            webSocket.onopen = () => {
                console.log('WebSocket Connected');
            };

            webSocket.onmessage = (event) => {
                if (isNotNullUndefinedBlank(event.data)) {
                    const data = JSON.parse(event.data);
                    const profitModel = {
                        todayProfit: data.todayProfit,
                        totalProfit: isNotNullUndefined(data.totalProfit) ? data.totalProfit : 0,
                        totalProfitPercentage: isNotNullUndefined(data.totalProfitPercentage) ? data.totalProfitPercentage : 0,
                        floatingValue: isNotNullUndefined(data.totalFloatingValue) ? data.totalFloatingValue : 0,
                        totalFloatingValuePercentage: isNotNullUndefined(data.totalFloatingValuePercentage) ? data.totalFloatingValuePercentage : 0,
                        availableFuel: isNotNullUndefined(data.availableFuel) ? data.availableFuel : 0,
                    };
                    setProfitModel(profitModel);
                };
            }
            webSocket.onclose = () => {
                console.log('WebSocket Disconnected');
            };

            return () => {
                webSocket.close();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userExchangeAccountId]);

    const topPerformerFilterTypes = [
        { id: 1, name: "Today" },
        { id: 2, name: "This Week" },
        { id: 3, name: "This Month" },
        { id: 4, name: "This Year" },
        { id: 5, name: "Lifetime" }
    ];

    const periodicalTypes = [
        { id: 1, name: "Daily" },
        { id: 2, name: "Weekly" },
        { id: 3, name: "Monthly" }
    ];

    useEffect(() => {
        getTopAssets.mutate({ userExchangeAccountId: userExchangeAccountId, periodicalTypeFilterType: periodicalTypeFilterType });
        onGetExchangeAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodicalTypeFilterType, userExchangeAccountId]);

    useEffect(() => {
        if (isUserStatusHasPermission(1) || isUserExchangeStatusHasPermission(1)) {
            navigate(statusWiseOnboardingRedirectToPage());
        }
        getHoldings();
        // Simulate loading delay with setTimeout
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the delay time as needed

        getDirectIndirectReferral.mutate({ userId });
        // getReferralUserEarning.mutate({ userId });
        getTopAssets.mutate({ userExchangeAccountId: userExchangeAccountId, periodicalTypeFilterType: periodicalTypeFilterType });
        getReferralEarning.mutate({});
        onGetExchangeAssets();
        getMostGainedUsers.mutate({ filterType: topPerformerFilterType, limit: limit.current, pageNumber: pageNumber.current, pageSize: pageSize.current });

        // Clear the timeout when the component unmounts
        return () => clearTimeout(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topPerformerFilterType, limit, pageNumber, pageSize]);

    return (
        <div className="py-8 px-9">
            <div className="w-full block xl:flex gap-5">
                <div className="w-full 3xl:w-1/4">
                    {loading ? (
                        <div className="bg-white p-6 bg-opacity-3">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="col-span-1">
                                    <div className="h-5 w-28 bg-grey-Payne rounded animate-pulse"></div>
                                    <div className="h-8 w-32 bg-grey-Payne rounded animate-pulse mt-2.5"></div>
                                </div>
                                <div className="col-span-1">
                                    <div className="h-5 w-24 bg-grey-Payne rounded animate-pulse"></div>
                                    <div className="flex justify-center items-center gap-2.5">
                                        <div className="h-6 w-28 bg-grey-Payne rounded animate-pulse mt-3"></div>
                                        <div className="h-6 w-12 bg-grey-Payne rounded-full animate-pulse mt-3"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-4">
                                <div className="col-span-1">
                                    <div className="h-5 w-32 bg-grey-Payne rounded animate-pulse"></div>
                                    <div className="h-12 w-28 bg-grey-Payne rounded-lg animate-pulse mt-2.5"></div>
                                </div>
                                <div className="col-span-1">
                                    <div className="h-5 w-24 bg-grey-Payne rounded animate-pulse"></div>
                                    <div className="flex justify-center items-center gap-2.5 mt-2.5">
                                        <div className="h-6 w-28 bg-grey-Payne rounded animate-pulse"></div>
                                        <div className="h-6 w-12 bg-grey-Payne rounded-full animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-4">
                                <div className="col-span-1 flex items-center gap-2.5">
                                    <div className="h-6 w-24 bg-grey-Payne rounded animate-pulse"></div>
                                    <div className="h-6 w-12 bg-grey-Payne rounded-full animate-pulse"></div>
                                </div>
                                <div className="col-span-1">
                                    <div className="h-6 w-28 bg-grey-Payne rounded-full animate-pulse"></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="bg-white bg-opacity-3 p-6 border-white border-opacity-20 border rounded" >
                            <div className="grid grid-cols-2 gap-4">
                                <div className="col-span-1">
                                    <p className="text-sm font-normal text-grey-suit">
                                        Today’s Profit
                                    </p>
                                    <p className="text-2xl font-semibold text-white mt-2.5">
                                        ${formatNumberWithCommas(profitModel.todayProfit)}
                                    </p>
                                </div>
                                <div className="col-span-1">
                                    <p className="text-sm font-normal text-grey-suit">
                                        Total Profit
                                    </p>
                                    <p className="text-17 font-semibold text-white mt-2.5">
                                        ${formatNumberWithCommas(profitModel.totalProfit)}
                                        <span className="bg-[#7B6AFE] text-white text-10 py-1 px-1.5 rounded-full ml-2.5"> {profitModel.totalProfitPercentage}%</span>
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-7">
                                <div className="col-span-1">
                                    <p className="text-sm font-normal text-grey-suit">
                                        Available Fuel($)
                                    </p>
                                    <p className="text-17 font-semibold text-white mt-2.5">
                                        ${formatNumberWithCommas(profitModel.availableFuel)}
                                        <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5"> +0%</span>
                                    </p>
                                </div>
                                <div className="col-span-1">
                                    <p className="text-sm font-normal text-grey-suit">
                                        Floating P&L
                                    </p>

                                    <p className="text-17 font-semibold text-white mt-2.5">
                                        ${formatNumberWithCommas(profitModel.floatingValue)}
                                        <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5"> {profitModel.totalFloatingValuePercentage}%</span>
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-7">
                                <div className="col-span-1">
                                </div>
                                <div className="col-span-1">
                                    <button className="flex gap-2 items-center border border-grey-tuna rounded-full w-max py-1.5 px-2.5 relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:rounded-full after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                        <img src={Images.recharge} className="w-2 h-3" alt="recharge" />
                                        <span className="font-normal text-white text-10">Recharge</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full 3xl:w-3/4 mt-5 xl:mt-0">
                    {loading ? (
                        <div className="bg-white p-6 bg-opacity-3">
                            <img src={Images.dashboardskeleton} className="w-[1120px] animate-pulse" alt="Dashboard-skeleton" />
                        </div>
                    ) : (
                        <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded h-full">
                            chart
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-7">
                <div>
                    {loading ? (
                        <div className="animate-pulse">
                            {/* Placeholder for content */}
                            <h2 className="flex items-center justify-between w-full">
                                <span className="bg-white bg-opacity-3 h-6 w-40 rounded"></span>
                                <p className="bg-white bg-opacity-3 h-6 w-[calc(100%-260px)] rounded"></p>
                                <button className="bg-white bg-opacity-3 h-6 w-20 rounded"></button>
                            </h2>
                        </div>
                    ) : (
                        <h2
                            className="flex items-center justify-between w-full">
                            <span className="text-[22px] text-white font-semibold">Top assets
                            </span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-260px)]"></p>
                            <select className="relative mt-1 w-28 text-white bg-grey-bunker shadow-lg cursor-pointer" value={periodicalTypeFilterType}
                                onChange={handlePeriodicalTypeFilterChange} >
                                {periodicalTypes.map(filter => (
                                    <option key={filter.id} value={filter.name.replace(/\s+/g, '')}>
                                        {filter.name}
                                    </option>
                                ))}
                            </select>
                            {/* <button className="text-xs text-white font-normal"> View all</button> */}
                        </h2>
                    )}
                </div>
                <div className="block 3xl:grid 3xl:grid-cols-3 gap-7 mt-8">
                    <div>
                        {loading ? (
                            <div className="bg-white bg-opacity-3 p-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-Payne w-10 h-10 flex justify-center items-center animate-pulse rounded-full">
                                        </div>
                                        <div className="ml-3">
                                            <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                            <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // topAssets here 
                            topAssets.map((asset, ix) => (
                                <div key={ix} className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="bg-grey-ebony-Clay w-10 h-10 flex justify-center items-center rounded-full">
                                                <div className="w-7 h-7">
                                                    <img
                                                        src={asset.assetLogoUrl ?? ""}
                                                        className="w-full"
                                                        alt="Coin logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                <h2 className="text-15 text-white font-semibold tracking-normal">
                                                    {asset.assetSymbol ?? ""}
                                                </h2>
                                                <p className="text-xs text-white font-normal tracking-normal">
                                                    {asset.assetName ?? ""}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Level
                                            </p>
                                            <p className="text-sm font-semibold text-white text-center mt-2.5">
                                                {asset.orderLevel}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Profit
                                            </p>
                                            <p className="text-sm font-semibold  text-emerald mt-2.5">
                                                ${asset.profit}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Order amount
                                            </p>
                                            <p className="text-sm font-semibold text-white mt-2.5">
                                                ${asset.orderAmount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    {/* <div>
                        {loading ? (
                            <div className="bg-white bg-opacity-3 p-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-Payne w-10 h-10 flex justify-center items-center animate-pulse rounded-full">
                                        </div>
                                        <div className="ml-3">
                                            <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                            <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded mt-5 3xl:mt-0">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-ebony-Clay w-10 h-10 flex justify-center items-center rounded-full">
                                            <div className="w-7 h-7">
                                                <img
                                                    src={Images.bitcoinbtc}
                                                    className="w-full"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h2 className="text-15 text-white font-semibold tracking-normal">
                                                BTC
                                            </h2>
                                            <p className="text-xs text-white font-normal tracking-normal">
                                                Bitcoin
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Level
                                        </p>
                                        <p className="text-sm font-semibold text-white text-center mt-2.5">
                                            11
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Profit
                                        </p>
                                        <p className="text-sm font-semibold  text-emerald mt-2.5">
                                            $865.5855
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Order amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            $32,123.3
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {loading ? (
                            <div className="bg-white bg-opacity-3 p-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-Payne w-10 h-10 flex justify-center items-center animate-pulse rounded-full">
                                        </div>
                                        <div className="ml-3">
                                            <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                            <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded mt-5 3xl:mt-0">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-ebony-Clay w-10 h-10 flex justify-center items-center rounded-full">
                                            <div className="w-7 h-7">
                                                <img
                                                    src={Images.bitcoinbtc}
                                                    className="w-full"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h2 className="text-15 text-white font-semibold tracking-normal">
                                                BTC
                                            </h2>
                                            <p className="text-xs text-white font-normal tracking-normal">
                                                Bitcoin
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Level
                                        </p>
                                        <p className="text-sm font-semibold text-white text-center mt-2.5">
                                            11
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Profit
                                        </p>
                                        <p className="text-sm font-semibold  text-emerald mt-2.5">
                                            $865.5855
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Order amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            $32,123.3
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
            <div className="mt-7">
                <div>
                    {loading ? (
                        <div className="animate-pulse">
                            {/* Placeholder for content */}
                            <h2 className="flex items-center justify-between w-full">
                                <span className="bg-white bg-opacity-3 h-6 w-40 rounded"></span>
                                <p className="bg-white bg-opacity-3 h-6 w-[calc(100%-260px)] rounded"></p>
                                <button className="bg-white bg-opacity-3 h-6 w-20 rounded"></button>
                            </h2>
                        </div>
                    ) : (
                        <h2
                            className="flex items-center justify-between w-full">
                            <span className="text-[22px] text-white font-semibold">
                                Top holdings
                            </span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-260px)]"></p>
                            {/* <button className="text-xs text-white font-normal"> View all</button> */}
                        </h2>
                    )}
                </div>
                <div className="block 2xl:grid 2xl:grid-cols-3 gap-7">
                        {loading ? (
                            <div className="bg-white bg-opacity-3 p-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-Payne w-10 h-10 flex justify-center items-center animate-pulse rounded-full">
                                        </div>
                                        <div className="ml-3">
                                            <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                            <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            topHoldings.map((holding, ix) => (
                                <div key={ix} className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded mt-8">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="bg-grey-ebony-Clay w-10 h-10 flex justify-center items-center rounded-full">
                                                <div className="w-7 h-7">
                                                    <img
                                                        src={holding.assetLogoUrl}
                                                        className="w-full"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="ml-3 w-[115px]">
                                                <h2 className="text-15 text-white font-semibold tracking-normal">
                                                    {holding.assetSymbol}
                                                </h2>
                                                <p className="text-xs text-white font-normal tracking-normal">
                                                    {holding.assetName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Level
                                            </p>
                                            <p className="text-sm font-semibold text-white text-center mt-2.5">
                                                {holding.orderLevel}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Profit
                                            </p>
                                            <p className="text-sm font-semibold  text-emerald mt-2.5">
                                                ${holding.profit > 0 ? holding.profit : 0}
                                            </p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Order amount
                                            </p>
                                            <p className="text-sm font-semibold text-white mt-2.5">
                                                ${holding.quoteQuantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    {/* <div>
                        {loading ? (
                            <div className="bg-white bg-opacity-3 p-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-Payne w-10 h-10 flex justify-center items-center animate-pulse rounded-full">
                                        </div>
                                        <div className="ml-3">
                                            <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                            <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-ebony-Clay w-10 h-10 flex justify-center items-center rounded-full">
                                            <div className="w-7 h-7">
                                                <img
                                                    src={Images.bitcoinbtc}
                                                    className="w-full"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h2 className="text-15 text-white font-semibold tracking-normal">
                                                BTC
                                            </h2>
                                            <p className="text-xs text-white font-normal tracking-normal">
                                                Bitcoin
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Level
                                        </p>
                                        <p className="text-sm font-semibold text-white text-center mt-2.5">
                                            11
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Profit
                                        </p>
                                        <p className="text-sm font-semibold  text-emerald mt-2.5">
                                            $865.5855
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Order amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            $32,123.3
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {loading ? (
                            <div className="bg-white bg-opacity-3 p-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-Payne w-10 h-10 flex justify-center items-center animate-pulse rounded-full">
                                        </div>
                                        <div className="ml-3">
                                            <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                            <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-grey-Payne h-3 w-12 rounded animate-pulse"></div>
                                        <div className="bg-grey-Payne h-4 w-16 rounded animate-pulse mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="bg-grey-ebony-Clay w-10 h-10 flex justify-center items-center rounded-full">
                                            <div className="w-7 h-7">
                                                <img
                                                    src={Images.bitcoinbtc}
                                                    className="w-full"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h2 className="text-15 text-white font-semibold tracking-normal">
                                                BTC
                                            </h2>
                                            <p className="text-xs text-white font-normal tracking-normal">
                                                Bitcoin
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Level
                                        </p>
                                        <p className="text-sm font-semibold text-white text-center mt-2.5">
                                            11
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Profit
                                        </p>
                                        <p className="text-sm font-semibold  text-emerald mt-2.5">
                                            $865.5855
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-sm font-normal text-grey-suit">
                                            Order amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            $32,123.3
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
            <div>
                {loading ? (
                    <div className="animate-pulse p-11 mt-7 bg-white bg-opacity-3">
                        {/* Placeholder for text */}
                    </div>
                ) : (
                    <div className="bg-gradient px-6 mt-7">
                        <div className="flex items-center justify-between">
                            <p className="text-base xl:text-xl 2xl:text-[28px] font-semibold text-white">Earn Big: Become a Partner!</p>
                            <p className="text-base xl:text-xl 2xl:text-2xl text-white font-normal">Earn up to $5000 per day with unlimited rewards</p>
                            <div className="w-[85px] mt-1">
                                <img src={Images.reward} className="w-full h-full" alt="Reward" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="mt-7 2xl:grid 2xl:grid-cols-2 block gap-11">
                <div>
                    {loading ? (
                        <div className="animate-pulse">
                            {/* Placeholder for content */}
                            <div className="bg-white bg-opacity-3 p-[25px] border-white  rounded mt-6">
                                {/* Placeholder for content */}
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center w-48 border-r-2 border-grey-tuna">
                                        <div className="w-8 h-8 flex justify-center items-center rounded-full bg-grey-Payne"></div>
                                        <div className="ml-3">
                                            <h2 className="bg-grey-Payne h-6 w-20 rounded"> </h2>
                                            <div>
                                                <h2 className="bg-grey-Payne h-5 w-28 rounded mt-2"> </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-48 border-r-2 border-grey-tuna">

                                        <div className="ml-3">
                                            <h2 className="bg-grey-Payne h-6 w-20 rounded"> </h2>
                                            <div>
                                                <h2 className="bg-grey-Payne h-5 w-28 rounded mt-2"> </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-48 border-r-2 border-grey-tuna">

                                        <div className="ml-3">
                                            <h2 className="bg-grey-Payne h-6 w-20 rounded"> </h2>
                                            <div>
                                                <h2 className="bg-grey-Payne h-5 w-28 rounded mt-2"> </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-28">
                                        <div className="ml-3">
                                            <h2 className="bg-grey-Payne h-6 w-20 rounded-full"> </h2>
                                        </div>
                                    </div>
                                    {/* Repeat placeholder elements for other sections */}
                                </div>
                                <div className="flex items-center justify-between mt-14">
                                    <div className="flex items-center w-48">
                                        <div className="ml-3">
                                            <h2 className="bg-grey-Payne h-6 w-44 rounded"> </h2>
                                            <div>
                                                <h2 className="bg-grey-Payne h-5 w-28 rounded mt-2"> </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-48">

                                        <div className="">
                                            <h2 className="bg-grey-Payne h-6 w-44 rounded"> </h2>
                                            <div>
                                                <h2 className="bg-grey-Payne h-5 w-28 rounded mt-2"> </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-28 contents">
                                        <div className="">
                                            <h2 className="bg-grey-Payne h-5 w-56 rounded"> </h2>
                                            <h2 className="bg-grey-Payne h-5 w-44 rounded mt-2"> </h2>
                                            <h2 className="bg-grey-Payne h-5 w-36 rounded mt-2"> </h2>
                                        </div>
                                    </div>
                                    {/* Repeat placeholder elements for other sections */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h2
                                className="flex items-center justify-between w-full">
                                <span className="text-[22px] text-white font-semibold">Direct Referral</span>
                                <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-193px)]"></p>
                            </h2>
                            <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded mt-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center w-48 border-r-2 border-grey-tuna">
                                        <div className="w-8 h-8 flex justify-center items-center rounded-full">
                                            <div className="w-8 h-8">
                                                <img
                                                    src={Images.daimond}
                                                    className="w-full"
                                                    alt="Daimond"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h2 className="text-sm text-white font-semibold tracking-normal">
                                                {directIndirectReferral.current.username ?? ""}
                                            </h2>
                                            <div>
                                                <span className="text-grey-nobel text-10 font-semibold">Level 1</span>
                                                <span className="text-grey-nobel text-10 font-semibold ml-2.5">Agent#72234</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1 w-48 text-center border-r-2 border-grey-tuna">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Direct Referral
                                        </p>
                                        <p className="text-sm font-semibold text-white text-center mt-2.5">
                                            {directIndirectReferral.current.directReferrals ?? 0}
                                        </p>
                                    </div>
                                    <div className="col-span-1 w-48 text-center border-r-2 border-grey-tuna">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Indirect Referral
                                        </p>
                                        <p className="text-sm font-semibold text-white text-center  mt-2.5">
                                            {directIndirectReferral.current.indirectReferrals ?? 0}
                                        </p>
                                    </div>
                                    <div className="col-span-1 w-44 flex justify-center">
                                        <button className="flex gap-2 items-center border border-grey-tuna rounded-full w-max py-1.5 px-2.5 relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:rounded-full after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                            <img src={Images.share} className="w-4 h-4" alt="recharge" />
                                            <span className="font-semibold text-white text-10">Share your link</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mt-14 mb-[56px]">
                                    <div className="flex items-center justify-between">
                                        <div className="col-span-1 w-52">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Your Total Commission
                                            </p>
                                            <p className="text-sm font-semibold text-white mt-2.5">
                                                ${referralEarning.current.totalReferralEarning}
                                                <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5">+2.83%</span>
                                            </p>
                                        </div>
                                        <div className="col-span-1 w-56">
                                            <p className="text-sm font-normal text-grey-suit">
                                                Total Community Commission
                                            </p>
                                            <p className="text-sm font-semibold text-white mt-2.5">
                                                ${referralEarning.current.communityCommission}
                                                <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5">+2.83%</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 w-64">
                                        <div className="flex items-center">
                                            <img src={Images.crown} className="w-5 h-5 mr-2" alt="Crown" />
                                            <p className="text-13 font-semibold  text-white">
                                                Only 3 referrals left to level up!
                                            </p>
                                        </div>
                                        <p className="text-10 font-normal text-grey-suit mt-2.5">
                                            Become a level 2 member and start earning average of $900 per month effortlessly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-7 2xl:mt-0">
                    <div>
                        {loading ? (
                            <div className="animate-pulse">
                                {/* Placeholder for content */}
                                <div className="mt-7 2xl:mt-0">
                                    <div className="bg-white bg-opacity-3 p-5 pb-0 rounded mt-6">
                                        <div className="flex items-end mt-11 justify-center">
                                            <div
                                                className="overflow-visible relative pt-12 pb-3.5 px-10 lg:px-16 2xl:px-10 3xl:px-16 flex justify-center rounded-t-[36px] rounded-bl-[36px] items-center bg-black-BlackCat">
                                                <div className="absolute -top-14">
                                                    <div className="">
                                                        <div className="shadow-lg rounded-full">
                                                            <div className="w-[88px] h-[88px] rounded-full bg-grey-Payne"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3 items-center p-8">
                                                </div>
                                            </div>
                                            <div
                                                className="overflow-visible relative p-12 flex justify-center rounded-t-[36px] items-center bg-white bg-opacity-12">
                                                <div className="absolute -top-14">
                                                    <div className="">
                                                        <div className="shadow-lg rounded-full">
                                                            <div className="w-[88px] h-[88px] rounded-full bg-grey-Payne"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3 items-center p-8">
                                                </div>
                                            </div>
                                            <div
                                                className="overflow-visible relative pt-12 pb-3.5 px-10 lg:px-16 2xl:px-10 3xl:px-16 flex justify-center rounded-t-[36px] rounded-br-[36px] items-center bg-black-BlackCat">
                                                <div className="absolute -top-14">
                                                    <div className="">
                                                        <div className="shadow-lg rounded-full">
                                                            <div className="w-[88px] h-[88px] rounded-full bg-grey-Payne"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3 items-center p-8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h2
                                    className="flex items-center justify-between w-full">
                                    <span className="text-[22px] text-white font-semibold">{topPerformerFilterName.current}’s top performer</span>
                                    <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-450px)]"></p>
                                    <select className="relative mt-1 w-28 text-white bg-grey-bunker shadow-lg cursor-pointer" value={topPerformerFilterType}
                                        onChange={handleTopPerformerFilterChange} >
                                        {topPerformerFilterTypes.map(filter => (
                                            <option key={filter.id} value={filter.name.replace(/\s+/g, '')}>
                                                {filter.name}
                                            </option>
                                        ))}
                                    </select>
                                </h2>
                                <div className="bg-white bg-opacity-3 p-5 pb-0 border-white border-opacity-20 border rounded mt-6">
                                    <div className="flex items-end mt-11 justify-center">
                                        <div className="relative rounded-xl">
                                            <div
                                                className="overflow-visible relative pt-12 pb-3.5 px-10 lg:px-16 2xl:px-10 3xl:px-16 flex justify-center rounded-t-[36px] rounded-bl-[36px] items-center bg-black-BlackCat">
                                                <div className="absolute -top-14">
                                                    <div className="">
                                                        <div className="shadow-lg rounded-full bg-gradient-to-t from-[#ff1f5e] to-[#fe682b] p-1 w-24 h-24">
                                                            <img src={mostGainedUser.find(x => x.srNo === 2)?.profileImageUrl} className="rounded-full w-full h-full object-cover" alt="Performer" />
                                                        </div>
                                                    </div>
                                                    <div className="h-8 w-8 lg:h-9 lg:w-9 text-lg rounded-lg bg-gradient-to-tl from-[#ff1f5e] to-[#fe682b] relative flex items-center justify-center top-[-22px] lg:top-[-25px] right-[-33px] lg:right-[-30px] transform origin-center rotate-45">
                                                        <p className="transform origin-center rotate-[315deg] text-white text-lg font-semibold">{mostGainedUser.find(x => x.srNo === 2)?.srNo}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3 items-center">
                                                    <span className="text-white text-xs font-semibold">{mostGainedUser.find(x => x.srNo === 2)?.fullName}</span>
                                                    <span className="text-grey-storm text-10 font-medium leading-6">Technical advisor</span>
                                                    <span className="text-white text-15 font-semibold">${mostGainedUser.find(x => x.srNo === 2)?.profit}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative rounded-xl">
                                            <div
                                                className="overflow-visible relative pt-12 px-10 lg:px-16 2xl:px-10 3xl:px-16 flex justify-center rounded-t-[36px] items-center bg-white bg-opacity-12">
                                                <div className="absolute -top-14">
                                                    <div className="w-24 h-24">
                                                        <img src={mostGainedUser.find(x => x.srNo === 1)?.profileImageUrl} className="rounded-full w-full h-full shadow-lg border-4 border-blue-deep-sky object-cover" alt="Performer" />
                                                    </div>

                                                    <div className="h-8 w-8 lg:h-9 lg:w-9 text-lg rounded-lg bg-blue-deep-sky relative flex items-center justify-center top-[-22px] lg:top-[-25px] right-[-33px] lg:right-[-30px] transform origin-center rotate-45">
                                                        <p className="transform origin-center rotate-[315deg] text-white text-lg font-semibold">{mostGainedUser.find(x => x.srNo === 1)?.srNo}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3 items-center">
                                                    <span className="text-white text-xs font-semibold">{mostGainedUser.find(x => x.srNo === 1)?.fullName}</span>
                                                    <span className="text-grey-storm text-10 font-medium leading-6">Technical advisor</span>
                                                    <span className="text-white text-15 font-semibold">${mostGainedUser.find(x => x.srNo === 1)?.profit}</span>
                                                    <div className="inline-grid items-center mt-3.5">
                                                        <img src={Images.top1} className="w-14 lg:w-16 h-auto" alt="Performer" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative rounded-xl">
                                            <div
                                                className="overflow-visible relative pt-12 pb-3.5 px-10 lg:px-16 2xl:px-10 3xl:px-16 flex justify-center rounded-t-[36px] rounded-br-[36px] items-center bg-black-BlackCat">
                                                <div className="absolute -top-14">
                                                    <div className="w-24 h-24">
                                                        <img src={mostGainedUser.find(x => x.srNo === 3)?.profileImageUrl} className="rounded-full w-full h-full shadow-lg border-4 border-camarone object-cover" alt="Performer" />
                                                    </div>
                                                    <div className="h-8 w-8 lg:h-9 lg:w-9 text-lg rounded-lg bg-emerald relative flex items-center justify-center top-[-22px] lg:top-[-25px] right-[-33px] lg:right-[-30px] transform origin-center rotate-45">
                                                        <p className="transform origin-center rotate-[315deg] text-white text-lg font-semibold">{mostGainedUser.find(x => x.srNo === 3)?.srNo}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3 items-center">
                                                    <span className="text-white text-xs font-semibold">{mostGainedUser.find(x => x.srNo === 3)?.fullName}</span>
                                                    <span className="text-grey-storm text-10 font-medium leading-6">Technical advisor</span>
                                                    <span className="text-white text-15 font-semibold">${mostGainedUser.find(x => x.srNo === 3)?.profit}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
