import { isValidJSON } from "../helpers/common";

const storagePrefix = 'ailtra_';

const storage = {
    getToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}token`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}token`)) : "";
    },
    setToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
    },
    clearToken: () => {
        window.localStorage.removeItem(`${storagePrefix}token`);
    },
    getRefreshToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}refreshToken`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}refreshToken`)) : "";
    },
    setRefreshToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
    },
    clearRefreshToken: () => {
        window.localStorage.removeItem(`${storagePrefix}refreshToken`);
    },
    isAuthorized: () => {
        const token = storage.getToken();
        return !!token;
    },
    setUserRoleId: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}userRoleId`, statusId);
    },
    getUserRoleId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userRoleId`));
    },
    clearUserRoleId: () => {
        return (window.localStorage.removeItem(`${storagePrefix}userRoleId`));
    },
    setUserStatusId: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}statusId`, statusId);
    },
    getUserStatusId: () => {
        return (window.localStorage.getItem(`${storagePrefix}statusId`));
    },
    clearUserStatusId: () => {
        return (window.localStorage.removeItem(`${storagePrefix}statusId`));
    },
    setUserReferrerCode: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}referrerCode`, statusId);
    },
    getUserReferrerCode: () => {
        return (window.localStorage.getItem(`${storagePrefix}referrerCode`));
    },
    clearUserReferrerCode: () => {
        return (window.localStorage.removeItem(`${storagePrefix}referrerCode`));
    },
    setUserFullName: (fullName) => {
        window.localStorage.setItem(`${storagePrefix}fullName`, fullName);
    },
    getUserFullName: () => {
        return (window.localStorage.getItem(`${storagePrefix}fullName`));
    },
    clearUserFullName: () => {
        return (window.localStorage.removeItem(`${storagePrefix}fullName`));
    },
    setUserExchangeStatusId: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}userExchangeStatusId`, statusId);
    },
    getUserExchangeStatusId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userExchangeStatusId`));
    },
    clearUserExchangeStatusId: () => {
        return (window.localStorage.removeItem(`${storagePrefix}userExchangeStatusId`));
    },
    setUserExchangeAccountId: (userExchangeAccountId) => {
        window.localStorage.setItem(`${storagePrefix}userExchangeAccountId`, userExchangeAccountId);
    },
    getUserExchangeAccountId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userExchangeAccountId`));
    },
    clearUserExchangeAccountId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userExchangeAccountId`));
    },
    setExchangeId: (exchangeId) => {
        window.localStorage.setItem(`${storagePrefix}exchangeId`, exchangeId);
    },
    getExchangeId: () => {
        return (window.localStorage.getItem(`${storagePrefix}exchangeId`));
    },
    setUserId: (userId) => {
        window.localStorage.setItem(`${storagePrefix}userId`, userId);
    },
    setEmailId: (emailId) => {
        window.localStorage.setItem(`${storagePrefix}emailId`, emailId);
    },
    setCodeType: (codeType) => {
        window.localStorage.setItem(`${storagePrefix}codeType`, codeType);
    },
    setPhoneNumber: (phoneNumber) => {
        window.localStorage.setItem(`${storagePrefix}phoneNumber`, phoneNumber);
    },
    setCountryId: (countryId) => {
        window.localStorage.setItem(`${storagePrefix}countryId`, countryId);
    },
    getUserId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userId`));
    },
    getEmailId: () => {
        return (window.localStorage.getItem(`${storagePrefix}emailId`));
    },
    getCodeType: () => {
        return (window.localStorage.getItem(`${storagePrefix}codeType`));
    },
    getPhoneNumber: () => {
        return (window.localStorage.getItem(`${storagePrefix}phoneNumber`));
    },
    getCountryId: () => {
        return (window.localStorage.getItem(`${storagePrefix}countryId`));
    },
    clearUserId: () => {
        window.localStorage.removeItem(`${storagePrefix}userId`);
    },
    clearEmailId: () => {
        window.localStorage.removeItem(`${storagePrefix}emailId`);
    },
    clearCodeType: () => {
        window.localStorage.removeItem(`${storagePrefix}codeType`);
    },
    clearPhoneNumber: () => {
        window.localStorage.removeItem(`${storagePrefix}phoneNumber`);
    },
    clearCountryId: () => {
        window.localStorage.removeItem(`${storagePrefix}countryId`);
    },
    setLoginProvider: (exchangeId) => {
        window.localStorage.setItem(`${storagePrefix}loginProvider`, exchangeId);
    },
    getLoginProvider: () => {
        return (window.localStorage.getItem(`${storagePrefix}loginProvider`));
    },
    setIsChangeEmail: (isChangeEmail) => {
        window.localStorage.setItem(`${storagePrefix}isChangeEmail`, isChangeEmail);
    },
    getIsChangeEmail: () => {
        return (window.localStorage.getItem(`${storagePrefix}isChangeEmail`));
    },
    clearIsChangeEmail: () => {
        window.localStorage.removeItem(`${storagePrefix}isChangeEmail`);
    },
    setIsAdminLoginAsUser: (status) => {
        window.localStorage.setItem(`${storagePrefix}isAdminLoginAsUser`, status);
    },
    getIsAdminLoginAsUser: () => {
        return (window.localStorage.getItem(`${storagePrefix}isAdminLoginAsUser`));
    },
    setDailingCode: (dialingCode) => {
        window.localStorage.setItem(`${storagePrefix}dialingCode`, dialingCode);
    },
    setCountryList: (countryList) => {
        localStorage.setItem(`${storagePrefix}countryList`, countryList);
    },
    getCountryList: () => {
        return localStorage.getItem(`${storagePrefix}countryList`);
    },
    setExchangeAssetsList: (exchangeAssetsList) => {
        localStorage.setItem(`${storagePrefix}exchangeAssetsList`, exchangeAssetsList);
    },
    getExchangeAssetsList: () => {
        return localStorage.getItem(`${storagePrefix}exchangeAssetsList`);
    },
    getDailingCode: () => {
        return (window.localStorage.getItem(`${storagePrefix}dialingCode`));
    },
    clearDailingCode: () => {
        window.localStorage.removeItem(`${storagePrefix}dailingCode`);
    },
    //This function setDynamicDataJSON updates or adds a property to a JSON object stored in localStorage under a key derived from variableName, ensuring the existing properties are preserved.
    setDynamicDataJSON: (variableName, propertyName, value) => {
        localStorage.setItem(`${storagePrefix}${variableName}`, JSON.stringify(
            {
                ...(JSON.parse(localStorage.getItem(`${storagePrefix}${variableName}`)) || {}),
                [propertyName]: value
            }));
    },
    //This function getDynamicDataJSON fetches and parses a JSON object from local storage using a dynamic key, then retrieves the specified property or returns an empty string if the property is not found. 
    getDynamicDataJSON: (variableName, propertyName) => {
        return JSON.parse(localStorage.getItem(`${storagePrefix}${variableName}`))?.[propertyName] || ''
    },
    //This function clears a specific property in a JSON object stored in localStorage, based on whether isAllClear is true or false.
    clearDynamicDataJSON: (variableName, propertyName, isAllClear = false) => {
        isAllClear ? (
            localStorage.removeItem(`${storagePrefix}${variableName}`)
        )
            : (
                localStorage.setItem(`${storagePrefix}${variableName}`, JSON.stringify({
                    ...JSON.parse(localStorage.getItem(`${storagePrefix}${variableName}`)) || {},
                    ...(propertyName ? { [propertyName]: undefined } : {})
                }))
            );
    }
};

export {
    storage
};