import { useState, useEffect, useRef, useMemo } from "react";
import Images from "../assets/images";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { useParams } from 'react-router-dom';
import { IExchangeAccountAssetPositions, IExchangeAccountAssetProfile } from "../types/exchange";
import IResponse from "../types/response";
import { useGetExchangeAssetByExchangeSymbolAndExchangeId, useGetUserAssetProfile } from "../services/exchange.service";
import WebSocketManager, { MessageHandler } from '../helpers/websocketmanager';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { storage } from "../utils/storage";
import { useAssetMarketWatchData } from '../helpers/datacontext';
import { formatNumberWithCommas } from "../helpers/commaformattednumber";
import { createChart, ColorType, IChartApi, ISeriesApi } from 'lightweight-charts';
import useRenewAccessToken, { handleError, isNotNullUndefined, isTokenValid } from "../helpers/common";

interface CoinData {
    coin: string;
    data: any;
}


const CoinProfile = () => {
    const { symbol } = useParams();
    const url = useRef("");
    const aggTrade = useRef("");
    const ticker = useRef("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [coinData, setCoinData] = useState<CoinData[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tickerData, setTickerData] = useState<CoinData[]>([]);
    const { updateCurrentAsset } = useAssetMarketWatchData();
    const oldMarketPrice = useRef(parseFloat("0").toFixed(2));
    const marketPrice = useRef(parseFloat("0").toFixed(2));
    const changePrice = useRef(parseFloat("0").toFixed(2));
    const changePricePercentage = useRef(parseFloat("0").toFixed(2));
    const lowPrice = useRef(parseFloat("0").toFixed(2));
    const highPrice = useRef(parseFloat("0").toFixed(2));
    const volumePrice = useRef(parseFloat("0").toFixed(2));
    const unrealizedPnL = useRef(parseFloat("0").toFixed(2));
    const unrealizedPnLPercentage = useRef(parseFloat("0").toFixed(2));
    const longAssetOrderLevelPercentage = useRef(0);
    const shortAssetOrderLevelPercentage = useRef(0);
    const isShowLongOrders = useRef(false);
    const isShowShortOrders = useRef(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [nagetivePositivestartDate, setnagetivePositiveStartDate] = useState<Date | null>(null);
    const assetId = useRef("");
    const renewAccessToken = useRenewAccessToken();

    const exchangeAccountAssetProfile = useRef<IExchangeAccountAssetProfile>({
        userExchangeAccountId: storage.getUserExchangeAccountId()?.toString() ?? "",
        assetId: "",
        assetName: "",
        assetLogoUrl: "",
        baseSymbol: "",
        exchangeSymbol: "",
        streamUrl: "",
        chartSymbol: "",
        tradingPair: "",
        pricePrecision: 0,
        quantityPrecision: 0,
        quotePrecision: 0
    });
    const longExchangeAccountAssetPosition = useRef<IExchangeAccountAssetPositions>({
        positionSide: "",
        assetQuantity: 0,
        averagePrice: 0,
        quoteQuantity: 0,
        leverage: 0,
        orderLevel: 0,
        maxOrderLevel: 0,
        nextOrderPrice: 0,
        orderSize: 0,
        takeProfitPrice: 0,
        unrealizedPnL: "0",
        unrealizedPnLPercentage: "0"
    });
    const shortExchangeAccountAssetPosition = useRef<IExchangeAccountAssetPositions>({
        positionSide: "",
        assetQuantity: 0,
        averagePrice: 0,
        quoteQuantity: 0,
        leverage: 0,
        orderLevel: 0,
        maxOrderLevel: 0,
        nextOrderPrice: 0,
        orderSize: 0,
        takeProfitPrice: 0,
        unrealizedPnL: "0",
        unrealizedPnLPercentage: "0"
    });

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const nagetivePositiveStartDate = (date: Date | null) => {
        setnagetivePositiveStartDate(date);
    };

    const binanceWebsockets = () => {
        const webSocketManager = new WebSocketManager();
        webSocketManager.connect(symbol ?? "", url.current);

        const promise = async () => { await webSocketManager.connect(symbol ?? "", url.current); };

        const openSockets = async () => {
            const promises = [promise()];
            await Promise.all(promises);

            webSocketManager.addMessageHandler(symbol ?? "", handleWebSocketMessage);
        };

        openSockets();

        return () => {
            webSocketManager.disconnect(symbol ?? "");
        };
    };

    const handleWebSocketMessage: MessageHandler = (coin, message) => {
        if (message.stream === aggTrade.current) {
            setCoinData((prevCoinData) => {
                const index = prevCoinData.findIndex((data) => data.coin === coin);
                if (index !== -1) {
                    marketPrice.current = parseFloat(message.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    if (marketPrice.current !== parseFloat(prevCoinData[index].data.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision)) {
                        oldMarketPrice.current = parseFloat(prevCoinData[index].data.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    }
                    prevCoinData[index].data = message;
                    return [...prevCoinData];
                }
                else {
                    marketPrice.current = parseFloat(message.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    oldMarketPrice.current = parseFloat(message.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    return [...prevCoinData, { coin, data: message }];
                }
            });
        }
        if (message.stream === ticker.current) {
            setTickerData((prevCoinData) => {
                const index = prevCoinData.findIndex((data) => data.coin === coin);
                if (index !== -1) {
                    changePrice.current = parseFloat(message.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    changePricePercentage.current = parseFloat(message.data.P).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    lowPrice.current = parseFloat(message.data.l).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    highPrice.current = parseFloat(message.data.h).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    volumePrice.current = parseFloat(message.data.v).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    prevCoinData[index].data = message;
                    return [...prevCoinData];
                }
                else {
                    changePrice.current = parseFloat(message.data.p).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    changePricePercentage.current = parseFloat(message.data.P).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    lowPrice.current = parseFloat(message.data.l).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    highPrice.current = parseFloat(message.data.h).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    volumePrice.current = parseFloat(message.data.v).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
                    return [...prevCoinData, { coin, data: message }];
                }
            });
        }
    };

    const getExchangeAssetByExchangeSymbolAndExchangeId = useGetExchangeAssetByExchangeSymbolAndExchangeId({
        onSuccess: async (response: IResponse) => {
            if (isNotNullUndefined(response.data)) {
                assetId.current = response.data.assetId;
                onGetUserAssetProfile(exchangeAccountAssetProfile.current.userExchangeAccountId, assetId.current);
            }
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    onGetExchangeAssetByExchangeSymbolAndExchangeId();
                }
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    const onGetExchangeAssetByExchangeSymbolAndExchangeId = async () => {
        let param = {
            "exchangeSymbol": symbol?.toUpperCase(),
            "exchangeId": 1
        };
        getExchangeAssetByExchangeSymbolAndExchangeId.mutate(param);
    };

    useEffect(() => {
        onGetExchangeAssetByExchangeSymbolAndExchangeId();

        marketPrice.current = parseFloat("0").toFixed(2);
        oldMarketPrice.current = parseFloat("0").toFixed(2);
        changePrice.current = parseFloat("0").toFixed(2);
        changePricePercentage.current = parseFloat("0").toFixed(2);
        lowPrice.current = parseFloat("0").toFixed(2);
        highPrice.current = parseFloat("0").toFixed(2);
        volumePrice.current = parseFloat("0").toFixed(2);
        unrealizedPnL.current = parseFloat("0").toFixed(2);
        unrealizedPnLPercentage.current = parseFloat("0").toFixed(2);
        longExchangeAccountAssetPosition.current = {
            positionSide: "",
            assetQuantity: 0,
            averagePrice: 0,
            quoteQuantity: 0,
            leverage: 0,
            orderLevel: 0,
            maxOrderLevel: 0,
            nextOrderPrice: 0,
            orderSize: 0,
            takeProfitPrice: 0,
            unrealizedPnL: "0",
            unrealizedPnLPercentage: "0"
        };
        shortExchangeAccountAssetPosition.current = {
            positionSide: "",
            assetQuantity: 0,
            averagePrice: 0,
            quoteQuantity: 0,
            leverage: 0,
            orderLevel: 0,
            maxOrderLevel: 0,
            nextOrderPrice: 0,
            orderSize: 0,
            takeProfitPrice: 0,
            unrealizedPnL: "0",
            unrealizedPnLPercentage: "0"
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol]);

    const onGetUserAssetProfile = async (userExchangeAccountId: string, assetId: string) => {
        let param = {
            "userExchangeAccountId": userExchangeAccountId,
            "assetId": assetId
        };
        getUserAssetProfile.mutate(param);
    };

    const getUserAssetProfile = useGetUserAssetProfile({
        onSuccess: (response: IResponse) => {
            if (response.data !== null && response.data !== undefined && response.data !== "") {
                exchangeAccountAssetProfile.current = response.data;
                exchangeAccountAssetProfile.current.userExchangeAccountId = storage.getUserExchangeAccountId()?.toString() ?? "";
                updateCurrentAsset({
                    assetName: exchangeAccountAssetProfile.current.assetName,
                    baseSymbol: exchangeAccountAssetProfile.current.baseSymbol,
                    exchangeSymbol: exchangeAccountAssetProfile.current.exchangeSymbol,
                    pricePrecision: exchangeAccountAssetProfile.current.pricePrecision,
                    assetLogoUrl: exchangeAccountAssetProfile.current.assetLogoUrl,
                    aggTrade: exchangeAccountAssetProfile.current.exchangeSymbol.toLowerCase() + "@aggTrade",
                    ticker: exchangeAccountAssetProfile.current.exchangeSymbol.toLowerCase() + "@ticker",
                    oldLastPrice: parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision),
                    lastPrice: parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision),
                    percentage: parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision)
                });
                if (response.data.positions.length > 0) {
                    let longAssetPosition = response.data.positions.find((x: { positionSide: string; }) => x.positionSide.toLowerCase() === "long");
                    let shortAssetPosition = response.data.positions.find((x: { positionSide: string; }) => x.positionSide.toLowerCase() === "short");
                    if (longAssetPosition !== null && longAssetPosition !== undefined) {
                        longExchangeAccountAssetPosition.current = longAssetPosition;
                        longAssetOrderLevelPercentage.current = parseInt(((longAssetPosition.orderLevel * 100) / (longAssetPosition.maxOrderLevel <= 0 ? 12 : longAssetPosition.maxOrderLevel)).toString());
                        isShowLongOrders.current = true;
                    }
                    else {
                        isShowLongOrders.current = false;
                    }
                    if (shortAssetPosition !== null && shortAssetPosition !== undefined) {
                        shortExchangeAccountAssetPosition.current = shortAssetPosition;
                        shortAssetOrderLevelPercentage.current = parseInt(((shortAssetPosition.orderLevel * 100) / (shortAssetPosition.maxOrderLevel <= 0 ? 12 : shortAssetPosition.maxOrderLevel)).toString());
                        isShowShortOrders.current = true;
                    }
                    else {
                        isShowShortOrders.current = false;
                    }
                }
                else {
                    isShowLongOrders.current = false;
                    isShowShortOrders.current = false;
                }
                aggTrade.current = exchangeAccountAssetProfile.current.exchangeSymbol.toLowerCase() + "@aggTrade";
                ticker.current = exchangeAccountAssetProfile.current.exchangeSymbol.toLowerCase() + "@ticker";
                url.current = exchangeAccountAssetProfile.current.streamUrl + "?streams=" + aggTrade.current + "/" + ticker.current;
                binanceWebsockets();
            }
            else {
                isShowLongOrders.current = false;
                isShowShortOrders.current = false;
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    useEffect(() => {
        if ((isShowLongOrders.current || isShowShortOrders.current) && parseFloat(marketPrice.current) > 0) {
            calculateUnrealizedPrice();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [longExchangeAccountAssetPosition.current, shortExchangeAccountAssetPosition.current, marketPrice.current]);

    const calculateUnrealizedPrice = () => {
        let longUnrealizedPnL = parseFloat(((parseFloat(marketPrice.current) - longExchangeAccountAssetPosition.current.averagePrice) * longExchangeAccountAssetPosition.current.assetQuantity).toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
        let longUnrealizedPnLPercentage = longUnrealizedPnL === parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision) && longExchangeAccountAssetPosition.current.quoteQuantity === 0 ? 0 : (parseFloat(longUnrealizedPnL) / longExchangeAccountAssetPosition.current.quoteQuantity) * 100;
        longExchangeAccountAssetPosition.current.unrealizedPnL = longUnrealizedPnL;
        longExchangeAccountAssetPosition.current.unrealizedPnLPercentage = parseFloat(longUnrealizedPnLPercentage.toString()).toFixed(2);

        let shortUnrealizedPnL = parseFloat(((shortExchangeAccountAssetPosition.current.averagePrice - parseFloat(marketPrice.current)) * shortExchangeAccountAssetPosition.current.assetQuantity).toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
        let shortUnrealizedPnLPercentage = shortUnrealizedPnL === parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision) && shortExchangeAccountAssetPosition.current.quoteQuantity === 0 ? 0 : (parseFloat(shortUnrealizedPnL) / shortExchangeAccountAssetPosition.current.quoteQuantity) * 100;
        shortExchangeAccountAssetPosition.current.unrealizedPnL = shortUnrealizedPnL;
        shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage = parseFloat(shortUnrealizedPnLPercentage.toString()).toFixed(2);

        unrealizedPnL.current = parseFloat((parseFloat(longUnrealizedPnL) + parseFloat(shortUnrealizedPnL)).toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision);
        if (longUnrealizedPnL === parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision) && longExchangeAccountAssetPosition.current.quoteQuantity === 0 && shortUnrealizedPnL === parseFloat("0").toFixed(exchangeAccountAssetProfile.current.pricePrecision) && shortExchangeAccountAssetPosition.current.quoteQuantity === 0) {
            unrealizedPnLPercentage.current = parseFloat("0").toFixed(2);
        }
        else {
            unrealizedPnLPercentage.current = parseFloat((((parseFloat(longUnrealizedPnL) + parseFloat(shortUnrealizedPnL)) / (longExchangeAccountAssetPosition.current.quoteQuantity + shortExchangeAccountAssetPosition.current.quoteQuantity)) * 100).toString()).toFixed(2);
        }
    };

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartNegativePositive = useRef<HTMLDivElement>(null);
    // const [value, setValue] = useState({ startDate: new Date(), endDate: new Date() });
    const selectedDate = useRef<string>(new Date().toISOString().split('T')[0]);
    const data = useMemo(() => [
        { time: '2021-03-28', value: 154 },
        { time: '2021-03-29', value: 154.2 },
        { time: '2021-03-30', value: 155.60001 },
        { time: '2021-04-02', value: 156.25 },
        { time: '2021-04-03', value: 156.25 },
        { time: '2021-04-04', value: 156.05 },
        { time: '2021-04-05', value: 158.05 },
        { time: '2021-04-06', value: 157.3 },
        { time: '2021-04-09', value: 144 },
        { time: '2021-04-10', value: 144.8 },
        { time: '2021-04-11', value: 143.5 },
        { time: '2021-04-12', value: 147.05 },
        { time: '2021-04-13', value: 144.85001 },
        { time: '2021-04-16', value: 145.39999 },
        { time: '2021-04-17', value: 147.3 },
        { time: '2021-04-18', value: 153.55 },
        { time: '2021-04-19', value: 150.95 },
        { time: '2021-04-20', value: 149.39999 },
        { time: '2021-04-23', value: 148.5 },
        { time: '2021-04-24', value: 146.60001 },
        { time: '2021-04-25', value: 144.45 },
        { time: '2021-04-26', value: 145.60001 },
        { time: '2021-04-27', value: 144.3 },
        { time: '2021-04-30', value: 144 },
        { time: '2021-05-02', value: 147.3 },
        { time: '2021-05-03', value: 144.2 },
        { time: '2021-05-04', value: 141.64999 },
        { time: '2021-05-07', value: 141.89999 },
        { time: '2021-05-08', value: 140.39999 },
        { time: '2021-05-10', value: 139.8 },
        { time: '2021-05-11', value: 137.5 },
        { time: '2021-05-14', value: 136.14999 },
        { time: '2021-05-15', value: 138 },
        { time: '2021-05-16', value: 137.95 },
        { time: '2021-05-17', value: 137.95 },
        { time: '2021-05-18', value: 136.75 },
        { time: '2021-05-21', value: 136.2 },
        { time: '2021-05-22', value: 135 },
        { time: '2021-05-23', value: 132.55 },
        { time: '2021-05-24', value: 132.7 },
        { time: '2021-05-25', value: 132.2 },
        { time: '2021-05-28', value: 131.55 },
        { time: '2021-05-29', value: 131.85001 },
        { time: '2021-05-30', value: 139.85001 },
        { time: '2021-05-31', value: 140.8 },
        { time: '2021-06-01', value: 139.64999 },
        { time: '2021-06-04', value: 137.10001 },
        { time: '2021-06-05', value: 139.25 },
        { time: '2021-06-06', value: 141.3 },
        { time: '2021-06-07', value: 145 },
        { time: '2021-06-08', value: 143.89999 },
        { time: '2021-06-11', value: 142.7 },
        { time: '2021-06-13', value: 144.05 },
        { time: '2021-06-14', value: 143.55 },
        { time: '2021-06-15', value: 140.5 },
        { time: '2021-06-18', value: 139.64999 },
        { time: '2021-06-19', value: 138 },
        { time: '2021-06-20', value: 141 },
        { time: '2021-06-21', value: 140.55 },
        { time: '2021-06-22', value: 140.55 },
        { time: '2021-06-25', value: 140.75 },
        { time: '2021-06-26', value: 140.64999 },
        { time: '2021-06-27', value: 141.14999 },
        { time: '2021-06-28', value: 139.8 },
        { time: '2021-06-29', value: 139.8 },
        { time: '2021-07-02', value: 140.14999 },
        { time: '2021-07-03', value: 143.05 },
        { time: '2021-07-04', value: 140 },
        { time: '2021-07-05', value: 129.2 },
        { time: '2021-07-06', value: 129.55 },
        { time: '2021-07-09', value: 128.3 },
        { time: '2021-07-10', value: 126.55 },
        { time: '2021-07-11', value: 124.3 },
        { time: '2021-07-12', value: 124.8 },
        { time: '2021-07-13', value: 123.25 },
        { time: '2021-07-16', value: 123 },
        { time: '2021-07-17', value: 124.25 },
        { time: '2021-07-18', value: 123 },
        { time: '2021-07-19', value: 121 },
        { time: '2021-07-20', value: 121.45 },
        { time: '2021-07-23', value: 123.8 },
        { time: '2021-07-24', value: 122.15 },
        { time: '2021-07-25', value: 121.3 },
        { time: '2021-07-26', value: 122.7 },
        { time: '2021-07-27', value: 122.2 },
        { time: '2021-07-30', value: 121.7 },
        { time: '2021-07-31', value: 122.95 },
        { time: '2021-08-01', value: 121 },
        { time: '2021-08-02', value: 116 },
        { time: '2021-08-03', value: 118.1 },
        { time: '2021-08-06', value: 114.3 },
        { time: '2021-08-07', value: 114.25 },
        { time: '2021-08-08', value: 111.85 },
        { time: '2021-08-09', value: 109.7 },
        { time: '2021-08-10', value: 105 },
        { time: '2021-08-13', value: 105.75 },
        { time: '2021-08-14', value: 107.25 },
        { time: '2021-08-15', value: 107.4 },
        { time: '2021-08-16', value: 110.5 },
        { time: '2021-08-17', value: 109 },
        { time: '2021-08-20', value: 108.95 },
        { time: '2021-08-21', value: 108.35 },
        { time: '2021-08-22', value: 108.6 },
        { time: '2021-08-23', value: 105.65 },
        { time: '2021-08-24', value: 104.45 },
        { time: '2021-08-27', value: 106.2 },
        { time: '2021-08-28', value: 106.55 },
        { time: '2021-08-29', value: 111.8 },
        { time: '2021-08-30', value: 115.5 },
        { time: '2021-08-31', value: 115.5 },
        { time: '2021-09-03', value: 114.55 },
        { time: '2021-09-04', value: 112.75 },
        { time: '2021-09-05', value: 111.5 },
        { time: '2021-09-06', value: 108.1 },
        { time: '2021-09-07', value: 108.55 },
        { time: '2021-09-10', value: 106.5 },
        { time: '2021-09-11', value: 105.1 },
        { time: '2021-09-12', value: 107.2 },
        { time: '2021-09-13', value: 107.1 },
        { time: '2021-09-14', value: 105.75 },
        { time: '2021-09-17', value: 106.05 },
        { time: '2021-09-18', value: 109.15 },
        { time: '2021-09-19', value: 111.4 },
        { time: '2021-09-20', value: 111 },
        { time: '2021-09-21', value: 111 },
        { time: '2021-09-24', value: 108.95 },
        { time: '2021-09-25', value: 106.65 },
        { time: '2021-09-26', value: 106.7 },
        { time: '2021-09-27', value: 107.05 },
        { time: '2021-09-28', value: 106.55 },
        { time: '2021-10-01', value: 106.2 },
        { time: '2021-10-02', value: 106.4 },
        { time: '2021-10-03', value: 107.1 },
        { time: '2021-10-04', value: 106.4 },
        { time: '2021-10-05', value: 104.65 },
        { time: '2021-10-08', value: 102.65 },
        { time: '2021-10-09', value: 102.1 },
        { time: '2021-10-10', value: 102.15 },
        { time: '2021-10-11', value: 100.9 },
        { time: '2021-10-12', value: 102 },
        { time: '2021-10-15', value: 100.15 },
        { time: '2021-10-16', value: 99 },
        { time: '2021-10-17', value: 98 },
        { time: '2021-10-18', value: 96 },
        { time: '2021-10-19', value: 95.5 },
        { time: '2021-10-22', value: 92.400002 },
        { time: '2021-10-23', value: 92.300003 },
        { time: '2021-10-24', value: 92.900002 },
        { time: '2021-10-25', value: 91.849998 },
        { time: '2021-10-26', value: 91.599998 },
        { time: '2021-10-29', value: 94.050003 },
        { time: '2021-10-30', value: 98.25 },
        { time: '2021-10-31', value: 97.25 },
        { time: '2021-11-01', value: 96.879997 },
        { time: '2021-11-02', value: 101.78 },
        { time: '2021-11-06', value: 102.4 },
        { time: '2021-11-07', value: 100.6 },
        { time: '2021-11-08', value: 98.5 },
        { time: '2021-11-09', value: 95.139999 },
        { time: '2021-11-12', value: 96.900002 },
        { time: '2021-11-13', value: 97.400002 },
        { time: '2021-11-14', value: 103.3 },
        { time: '2021-11-15', value: 102.74 },
        { time: '2021-11-16', value: 101.2 },
        { time: '2021-11-19', value: 98.720001 },
        { time: '2021-11-20', value: 102.2 },
        { time: '2021-11-21', value: 108.8 },
        { time: '2021-11-22', value: 109.9 },
        { time: '2021-11-23', value: 113.74 },
        { time: '2021-11-26', value: 110.9 },
        { time: '2021-11-27', value: 113.28 },
        { time: '2021-11-28', value: 113.6 },
        { time: '2021-11-29', value: 113.14 },
        { time: '2021-11-30', value: 114.4 },
        { time: '2021-12-03', value: 111.84 },
        { time: '2021-12-04', value: 106.7 },
        { time: '2021-12-05', value: 107.8 },
        { time: '2021-12-06', value: 106.44 },
        { time: '2021-12-07', value: 103.7 },
        { time: '2021-12-10', value: 101.02 },
        { time: '2021-12-11', value: 102.72 },
        { time: '2021-12-12', value: 101.8 },
        { time: '2021-12-13', value: 102 },
        { time: '2021-12-14', value: 102.1 },
        { time: '2021-12-17', value: 101.04 },
        { time: '2021-12-18', value: 101.6 },
        { time: '2021-12-19', value: 102 },
        { time: '2021-12-20', value: 102.02 },
        { time: '2021-12-21', value: 102.2 },
        { time: '2021-12-24', value: 102.1 },
        { time: '2021-12-25', value: 100.8 },
        { time: '2021-12-26', value: 101.02 },
        { time: '2021-12-27', value: 100.5 },
        { time: '2021-12-28', value: 101 },
        { time: '2022-01-03', value: 101.5 },
        { time: '2022-01-04', value: 101.1 },
        { time: '2022-01-08', value: 101.1 },
        { time: '2022-01-09', value: 102.06 },
        { time: '2022-01-10', value: 105.14 },
        { time: '2022-01-11', value: 104.66 },
        { time: '2022-01-14', value: 106.22 },
        { time: '2022-01-15', value: 106.98 },
        { time: '2022-01-16', value: 108.4 },
        { time: '2022-01-17', value: 109.06 },
        { time: '2022-01-18', value: 107 },
        { time: '2022-01-21', value: 105.8 },
        { time: '2022-01-22', value: 105.24 },
        { time: '2022-01-23', value: 105.4 },
        { time: '2022-01-24', value: 105.38 },
        { time: '2022-01-25', value: 105.7 },
        { time: '2022-01-28', value: 105.8 },
        { time: '2022-01-29', value: 106.1 },
        { time: '2022-01-30', value: 108.6 },
        { time: '2022-01-31', value: 107.92 },
        { time: '2022-02-01', value: 105.22 },
        { time: '2022-02-04', value: 102.44 },
        { time: '2022-02-05', value: 102.26 },
        { time: '2022-02-06', value: 102 },
        { time: '2022-02-07', value: 101.62 },
        { time: '2022-02-08', value: 101.9 },
        { time: '2022-02-11', value: 101.78 },
        { time: '2022-02-12', value: 102.7 },
        { time: '2022-02-13', value: 100.14 },
        { time: '2022-02-14', value: 101.36 },
        { time: '2022-02-15', value: 101.62 },
        { time: '2022-02-18', value: 100.74 },
        { time: '2022-02-19', value: 100 },
        { time: '2022-02-20', value: 100.04 },
        { time: '2022-02-21', value: 100 },
        { time: '2022-02-22', value: 100.12 },
        { time: '2022-02-25', value: 100.04 },
        { time: '2022-02-26', value: 98.980003 },
        { time: '2022-02-27', value: 97.699997 },
        { time: '2022-02-28', value: 97.099998 },
        { time: '2022-03-01', value: 96.760002 },
        { time: '2022-03-04', value: 98.360001 },
        { time: '2022-03-05', value: 96.260002 },
        { time: '2022-03-06', value: 98.120003 },
        { time: '2022-03-07', value: 99.68 },
        { time: '2022-03-11', value: 102.1 },
        { time: '2022-03-12', value: 100.22 },
        { time: '2022-03-13', value: 100.5 },
        { time: '2022-03-14', value: 99.660004 },
        { time: '2022-03-15', value: 100.08 },
        { time: '2022-03-18', value: 99.919998 },
        { time: '2022-03-19', value: 99.459999 },
        { time: '2022-03-20', value: 98.220001 },
        { time: '2022-03-21', value: 97.300003 },
        { time: '2022-03-22', value: 97.660004 },
        { time: '2022-03-25', value: 97 },
        { time: '2022-03-26', value: 97.019997 },
        { time: '2022-03-27', value: 96.099998 },
        { time: '2022-03-28', value: 96.699997 },
        { time: '2022-03-29', value: 96.300003 },
        { time: '2022-04-01', value: 97.779999 },
        { time: '2022-04-02', value: 98.360001 },
        { time: '2022-04-03', value: 98.5 },
        { time: '2022-04-04', value: 98.360001 },
        { time: '2022-04-05', value: 99.540001 },
        { time: '2022-04-08', value: 98.580002 },
        { time: '2022-04-09', value: 97.239998 },
        { time: '2022-04-10', value: 97.32 },
        { time: '2022-04-11', value: 98.400002 },
        { time: '2022-04-12', value: 98.220001 },
        { time: '2022-04-15', value: 98.720001 },
        { time: '2022-04-16', value: 99.120003 },
        { time: '2022-04-17', value: 98.620003 },
        { time: '2022-04-18', value: 98 },
        { time: '2022-04-19', value: 97.599998 },
        { time: '2022-04-22', value: 97.599998 },
        { time: '2022-04-23', value: 96.800003 },
        { time: '2022-04-24', value: 96.32 },
        { time: '2022-04-25', value: 96.339996 },
        { time: '2022-04-26', value: 97.120003 },
        { time: '2022-04-29', value: 96.980003 },
        { time: '2022-04-30', value: 96.32 },
        { time: '2022-05-02', value: 96.860001 },
        { time: '2022-05-03', value: 96.699997 },
        { time: '2022-05-06', value: 94.940002 },
        { time: '2022-05-07', value: 94.5 },
        { time: '2022-05-08', value: 94.239998 },
        { time: '2022-05-10', value: 92.900002 },
        { time: '2022-05-13', value: 91.279999 },
        { time: '2022-05-14', value: 91.599998 },
        { time: '2022-05-15', value: 90.080002 },
        { time: '2022-05-16', value: 91.68 },
        { time: '2022-05-17', value: 91.959999 },
        { time: '2022-05-20', value: 91.080002 },
        { time: '2022-05-21', value: 90.760002 },
        { time: '2022-05-22', value: 91 },
        { time: '2022-05-23', value: 90.739998 },
        { time: '2022-05-24', value: 91 },
        { time: '2022-05-27', value: 91.199997 },
        { time: '2022-05-28', value: 90.68 },
        { time: '2022-05-29', value: 91.120003 },
        { time: '2022-05-30', value: 90.419998 },
        { time: '2022-05-31', value: 93.800003 },
        { time: '2022-06-03', value: 96.800003 },
        { time: '2022-06-04', value: 96.34 },
        { time: '2022-06-05', value: 95.94 },
        { time: '2022-12-22', value: 32.51 },
        { time: '2022-12-23', value: 31.11 },

    ], []);
    const data1 = useMemo(() => [
        { time: '2022-01-18', value: -107 },
        { time: '2022-01-21', value: -105.8 },
        { time: '2022-01-22', value: -105.24 },
        { time: '2022-01-23', value: -105.4 },
        { time: '2022-01-24', value: -105.38 },
        { time: '2022-01-25', value: -105.7 },
        { time: '2022-01-28', value: 105.8 },
        { time: '2022-01-29', value: 106.1 },
        { time: '2022-01-30', value: -108.6 },
        { time: '2022-01-31', value: -107.92 },
        { time: '2022-02-01', value: 105.22 },
        { time: '2022-02-04', value: -102.44 },
        { time: '2022-02-05', value: 102.26 },
        { time: '2022-02-06', value: -102 },
        { time: '2022-02-07', value: -101.62 },
        { time: '2022-02-08', value: 101.9 },
        { time: '2022-02-11', value: 101.78 },
        { time: '2022-02-12', value: -102.7 },
        { time: '2022-02-13', value: 100.14 },
        { time: '2022-02-14', value: -101.36 },
        { time: '2022-02-15', value: 101.62 },
        { time: '2022-02-18', value: 100.74 },
        { time: '2022-02-19', value: -100 },
        { time: '2022-02-20', value: 100.04 },
        { time: '2022-02-21', value: 100 },
        { time: '2022-02-22', value: 100.12 },
        { time: '2022-02-25', value: 100.04 },
        { time: '2022-02-26', value: 98.980003 },
        { time: '2022-02-27', value: -97.699997 },
        { time: '2022-02-28', value: 97.099998 },
        { time: '2022-03-01', value: -96.760002 },
        { time: '2022-03-04', value: 98.360001 },
        { time: '2022-03-05', value: -96.260002 },
        { time: '2022-03-06', value: 98.120003 },
        { time: '2022-03-07', value: 99.68 },
        { time: '2022-03-11', value: 102.1 },
        { time: '2022-03-12', value: -100.22 },
        { time: '2022-03-13', value: 100.5 },
        { time: '2022-03-14', value: -99.660004 },
        { time: '2022-03-15', value: 100.08 },
        { time: '2022-03-18', value: -99.919998 },
        { time: '2022-03-19', value: -99.459999 },
        { time: '2022-03-20', value: -98.220001 },
        { time: '2022-03-21', value: 97.300003 },
        { time: '2022-03-22', value: 97.660004 },
        { time: '2022-03-25', value: 97 },
        { time: '2022-03-26', value: -97.019997 },
        { time: '2022-03-27', value: -96.099998 },
        { time: '2022-03-28', value: 96.699997 },
        { time: '2022-03-29', value: 96.300003 },
        { time: '2022-04-01', value: -97.779999 },
        { time: '2022-04-02', value: 98.360001 },
        { time: '2022-04-03', value: -98.5 },
        { time: '2022-04-04', value: 98.360001 },
        { time: '2022-04-05', value: -99.540001 },
        { time: '2022-04-08', value: 98.580002 },
        { time: '2022-04-09', value: 97.239998 },
        { time: '2022-04-10', value: -97.32 },
        { time: '2022-04-11', value: 98.400002 },
        { time: '2022-04-12', value: 98.220001 },
        { time: '2022-04-15', value: -98.720001 },
        { time: '2022-04-16', value: 99.120003 },
        { time: '2022-04-17', value: 98.620003 },
        { time: '2022-04-18', value: 98 },
        { time: '2022-04-19', value: 97.599998 },
        { time: '2022-04-22', value: 97.599998 },
        { time: '2022-04-23', value: -96.800003 },
        { time: '2022-04-24', value: 96.32 },
        { time: '2022-04-25', value: -96.339996 },
        { time: '2022-04-26', value: 97.120003 },
        { time: '2022-04-29', value: 96.980003 },
        { time: '2022-04-30', value: 96.32 },
        { time: '2022-05-02', value: -96.860001 },
        { time: '2022-05-03', value: 96.699997 },
        { time: '2022-05-06', value: -94.940002 },
        { time: '2022-05-07', value: 94.5 },
        { time: '2022-05-08', value: -94.239998 },
        { time: '2023-01-23', value: 17.98 },
        { time: '2023-01-24', value: -16.72 },
        { time: '2023-01-25', value: 16.35 },
        { time: '2023-01-26', value: -15.89 },
        { time: '2023-01-27', value: 15.46 },
        { time: '2023-01-28', value: -14.72 },
        { time: '2023-01-29', value: 14.35 },
        { time: '2023-01-30', value: -13.89 },
        { time: '2023-01-31', value: 13.46 },
        { time: '2024-01-01', value: 25.47 },
        { time: '2024-01-02', value: 16.35 },
        { time: '2024-01-03', value: -15.89 },
        { time: '2024-01-04', value: 15.46 },
        { time: '2024-01-05', value: -14.72 },
        { time: '2024-01-06', value: 14.35 },
    ], []);
    const [chartSeries, setChartSeries] = useState<ISeriesApi<"Histogram"> | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tooltipInfo, setTooltipInfo] = useState<{ date: string; value: number }>(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        const defaultDataPoint = data.find(d => d.time === currentDate) || { time: currentDate, value: 0 };
        return {
            date: currentDate,
            value: defaultDataPoint.value,
        };
    });

    useEffect(() => {
        if (chartContainerRef.current) {
            const container = chartContainerRef.current;
            const chart: IChartApi = createChart(container, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                layout: {
                    background: {
                        type: ColorType.Solid,
                        color: 'transparent',
                    },
                    textColor: 'white',
                },
                grid: {
                    horzLines: {
                        color: '#2e2e38', //border color
                    },
                    vertLines: {
                        visible: false,
                    },
                },
                rightPriceScale: {
                    visible: false,
                },
                leftPriceScale: {
                    visible: true,
                    borderVisible: true,
                },
                timeScale: {
                    borderVisible: true,
                },
                crosshair: {
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
            });

            const series = chart.addAreaSeries({
                topColor: 'rgba(141, 198, 63, 0.26)',
                bottomColor: 'rgba(0, 174, 239, 0)',
                lineColor: 'rgba(141, 198, 63, 1)',
                lineWidth: 2,
                priceLineVisible: false,
                lastValueVisible: false,
            });

            series.setData(data);

            document.body.style.position = 'relative';
            const toolTip = document.createElement('div');
            toolTip.style.cssText = `position: absolute; display: none; padding: 2px; font-size: 3px; text-align: center; z-index: 1000; top: 12px; pointer-events: none;`;
            container.appendChild(toolTip);

            chart.subscribeCrosshairMove((param) => {
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > container.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > container.clientHeight
                ) {
                    toolTip.style.display = 'none';
                } else {
                    toolTip.style.display = 'block';
                    const data: any = param.seriesData.get(series);
                    const price = data.value !== undefined ? data.value : 0;
                    toolTip.innerHTML = `<div class="relative -top-1 block items-center rounded-full bg-gradient px-2 py-0.5 text-10 font-semibold text-white after:absolute after:bottom-[-8px] after:left-[50%] after:right-auto after:top-auto after:-translate-x-1/2 after:border-4 after:border-solid after:border-b-transparent after:border-l-transparent after:border-r-transparent after:border-t-[#34b8ac] first-letter:after:content-['*']">
                ${Math.round(100 * price) / 100}
                </div>`;
                    let left: any = param.point.x + 40;
                    toolTip.style.left = left + 'px';
                    toolTip.style.top = 0 + 'px';
                }
            });
            const resizeObserver = new ResizeObserver(() => {
                if (chartContainerRef.current) {
                    chart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
                }
            });
            resizeObserver.observe(chartContainerRef.current);

            return () => {
                resizeObserver.disconnect();
                chart.remove();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // negative and positive chart start
    useEffect(() => {
        if (chartNegativePositive.current) {
            const container = chartNegativePositive.current;
            const chart: IChartApi = createChart(container, {
                width: chartNegativePositive.current.clientWidth,
                height: chartNegativePositive.current.clientHeight,
                layout: {
                    background: {
                        type: ColorType.Solid,
                        color: 'transparent',
                    },
                    textColor: 'white',
                },
                grid: {
                    horzLines: {
                        color: '#2e2e38', //border color
                    },
                    vertLines: {
                        visible: false,
                    },
                },
                rightPriceScale: {
                    visible: false,
                },
                leftPriceScale: {
                    visible: true,
                    borderVisible: true,
                },
                timeScale: {
                    borderVisible: true,
                },
                crosshair: {
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
            });

            const series = chart.addHistogramSeries({
                priceFormat: {
                    type: 'price',
                },
                priceLineVisible: false,
                lastValueVisible: false,
                base: 0,
                baseLineWidth: 2,
                priceScaleId: 'left',
            });

            series.setData(data1.map(({ time, value }) => ({
                time,
                value,
                color: value >= 0 ? '#117E31' : '#F54C4C',
            })));
            setChartSeries(series);


            series.setData(data1);

            document.body.style.position = 'relative';
            const toolTip = document.createElement('div');
            toolTip.style.cssText = `position: absolute; display: none; padding: 2px; font-size: 3px; text-align: center; z-index: 1000; top: 12px; pointer-events: none;`;
            container.appendChild(toolTip);

            chart.subscribeCrosshairMove((param) => {
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > container.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > container.clientHeight
                ) {
                    toolTip.style.display = 'none';
                } else {
                    toolTip.style.display = 'block';
                    const data: any = param.seriesData.get(series);
                    const price = data.value !== undefined ? data.value : 0;
                    toolTip.innerHTML = `<div class="relative -top-1 block items-center rounded-full bg-gradient px-2 py-0.5 text-10 font-semibold text-white after:absolute after:bottom-[-8px] after:left-[50%] after:right-auto after:top-auto after:-translate-x-1/2 after:border-4 after:border-solid after:border-b-transparent after:border-l-transparent after:border-r-transparent after:border-t-[#34b8ac] first-letter:after:content-['*']">
                ${Math.round(100 * price) / 100}
                </div>`;
                    let left: any = param.point.x + 40;
                    toolTip.style.left = left + 'px';
                    toolTip.style.top = 0 + 'px';
                }
            });
            const resizeObserver = new ResizeObserver(() => {
                if (chartNegativePositive.current) {
                    chart.resize(chartNegativePositive.current.clientWidth, chartNegativePositive.current.clientHeight);
                }
            });
            resizeObserver.observe(chartNegativePositive.current);

            return () => {
                resizeObserver.disconnect();
                chart.remove();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data1]);

    // negative and positive chart end
    const handleDateChange = (range: string) => {
        // Calculate the new date range based on the range provided
        const endDate = new Date(selectedDate.current);
        let startDate = new Date(selectedDate.current);
        switch (range) {
            case '1D':
                startDate.setDate(endDate.getDate() - 1);
                break;
            case '7D':
                startDate.setDate(endDate.getDate() - 7);
                break;
            case '1M':
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case '3M':
                startDate.setMonth(endDate.getMonth() - 3);
                break;
            case '1Y':
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            case '2Y':
                startDate.setFullYear(endDate.getFullYear() - 2);
                break;
            default:
                return;
        }
        // Filter the dataset
        const filteredData = data1.filter(d => {
            const date = new Date(d.time as string);
            return date >= startDate && date <= endDate;
        });
        // Update the colors based on the new filtered data
        const updatedFilteredData = filteredData.map(({ time, value }) => ({
            time,
            value,
            color: value >= 0 ? '#117E31' : '#F54C4C',
        }));
        // Update the chart
        chartSeries?.setData(updatedFilteredData);
    };
    return (
        <div className="py-8 px-9">
            <div className="w-full gap-8 3xl:flex block">
                <div className="3xl:w-[70%] w-full">
                    <div className="border-2 border-grey-bunker block lg:flex items-center">
                        <div className="py-8.5 px-5 w-full lg:w-2/5 border-r-2 border-grey-bunker flex justify-between items-center">
                            <div className="flex items-center">
                                <div className="bg-grey-bunker w-10 h-10 flex justify-center items-center rounded-full">
                                    <div className="w-7 h-7">
                                        <img
                                            src={exchangeAccountAssetProfile.current.assetLogoUrl}
                                            className="w-full rounded-full"
                                            alt="Coin logo"
                                        />
                                    </div>
                                </div>
                                <div className="ml-3">
                                    <h2 className="text-17 text-white font-semibold tracking-normal">
                                        {exchangeAccountAssetProfile.current.tradingPair}
                                    </h2>
                                    <p className="text-sm text-white font-normal tracking-normal">
                                        {exchangeAccountAssetProfile.current.assetName}
                                    </p>
                                </div>
                            </div>
                            <div>
                                {marketPrice.current > oldMarketPrice.current ? (
                                    <h2 className="text-25 text-emerald font-semibold tracking-normal">
                                        {/* {formatNumberWithCommas(marketPrice.current)} */}
                                        {marketPrice.current}
                                    </h2>
                                ) : marketPrice.current < oldMarketPrice.current ? (
                                    <h2 className="text-25 text-persian font-semibold tracking-normal">
                                        {/* {formatNumberWithCommas(marketPrice.current)} */}
                                        {marketPrice.current}
                                    </h2>
                                ) : (
                                    <h2 className="text-25 text-emerald font-semibold tracking-normal">
                                        {/* {formatNumberWithCommas(marketPrice.current)} */}
                                        {marketPrice.current}
                                    </h2>
                                )}
                            </div>
                        </div>
                        <div className="w-full lg:w-3/5 px-5 py-8.5 flex items-center justify-between">
                            <div className="min-w-[135px] xl:min-w-[185px]">
                                <p className="text-[11px] xl:text-13 text-white font-normal tracking-normal">
                                    24h Change
                                </p>
                                <h2 className="text-13 xl:text-base text-persian font-semibold tracking-normal mt-2">
                                    {formatNumberWithCommas(changePrice.current)} <span className="text-xs text-persian font-normal">({changePricePercentage.current}%)</span>
                                </h2>
                            </div>
                            <div className="min-w-[75px]">
                                <p className="text-[11px] xl:text-13 text-white font-normal tracking-normal">
                                    24h Low
                                </p>
                                <h2 className="text-13 xl:text-base text-white font-semibold tracking-normal mt-2">
                                    {formatNumberWithCommas(lowPrice.current)}
                                </h2>
                            </div>
                            <div className="min-w-[75px]">
                                <p className="text-[11px] xl:text-13 text-white font-normal tracking-normal">
                                    24h high
                                </p>
                                <h2 className="text-13 xl:text-base text-white font-semibold tracking-normal mt-2">
                                    {formatNumberWithCommas(highPrice.current)}
                                </h2>
                            </div>
                            <div className="min-w-[160px] xl:min-w-[180px]">
                                <p className="text-[11px] xl:text-13 text-white font-normal tracking-normal">
                                    24h Volume({exchangeAccountAssetProfile.current.baseSymbol})
                                </p>
                                <h2 className="text-13 xl:text-base text-white font-semibold tracking-normal mt-2">
                                    {formatNumberWithCommas(volumePrice.current)}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8">
                        <h1 className="text-white font-semibold text-base mb-5">{exchangeAccountAssetProfile.current.baseSymbol} chart</h1>
                        {exchangeAccountAssetProfile.current.chartSymbol !== "" && exchangeAccountAssetProfile.current.chartSymbol !== null && exchangeAccountAssetProfile.current.chartSymbol !== undefined ? (
                            <AdvancedRealTimeChart theme="dark" symbol={exchangeAccountAssetProfile.current.chartSymbol} width={'100%'} allow_symbol_change={false} interval="15"></AdvancedRealTimeChart>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div className="border-2 border-grey-bunker flex items-center mt-14">
                        <div className="py-9 px-5 pr-12 w-1/2 border-r-2 border-grey-bunker flex justify-between items-center">
                            <div className="flex items-center">
                                <div className="bg-grey-bunker w-10 h-10 flex justify-center items-center rounded-full">
                                    <div className="w-7 h-7">
                                        <img
                                            src={exchangeAccountAssetProfile.current.assetLogoUrl}
                                            className="w-full rounded-full"
                                            alt="Coin logo"
                                        />
                                    </div>
                                </div>
                                <div className="ml-3">
                                    <h2 className="text-lg text-white font-semibold tracking-normal">
                                        {exchangeAccountAssetProfile.current.tradingPair}
                                    </h2>
                                    <p className="text-sm text-white font-normal tracking-normal">
                                        {exchangeAccountAssetProfile.current.assetName}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p className="text-xs text-white font-normal tracking-normal">
                                    Total profit
                                </p>
                                <h2 className="text-lg font-semibold text-emerald mt-2.5 flex items-center">
                                    $45,193,56.25
                                    <span className="bg-holly text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                        +2.83%
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className="w-1/2 pl-12 pr-12 flex items-center justify-between">
                            <div>
                                <div className="flex items-center">
                                    <p className="text-xs font-normal text-white">
                                        Today’s P&L
                                    </p>
                                    <div className="w-max">
                                        <img
                                            src={Images.info}
                                            className="w-4 h-4 ml-2"
                                            alt="Info"
                                        />
                                    </div>
                                </div>
                                <p className="text-xs text-emerald font-semibold tracking-normal mt-2">
                                    +134.2 <span>USD</span>
                                </p>
                                <p className="text-xs text-emerald font-semibold tracking-normal mt-2">
                                    +0.55%
                                </p>
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <p className="text-xs font-normal text-white">
                                        7 day P&L
                                    </p>
                                    <div className="w-max">
                                        <img
                                            src={Images.info}
                                            className="w-4 h-4 ml-2"
                                            alt="Info"
                                        />
                                    </div>
                                </div>
                                <p className="text-xs text-emerald font-semibold tracking-normal mt-2">
                                    +952.4 <span>USD</span>
                                </p>
                                <p className="text-xs text-emerald font-semibold tracking-normal mt-2">
                                    +4.03%
                                </p>
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <p className="text-xs font-normal text-white">
                                        30 day P&L
                                    </p>
                                    <div className="w-max">
                                        <img
                                            src={Images.info}
                                            className="w-4 h-4 ml-2"
                                            alt="Info"
                                        />
                                    </div>
                                </div>
                                <p className="text-xs font-semibold text-emerald mt-2.5">
                                    +2990.3<span> USD</span>
                                </p>
                                <p className="text-xs font-semibold text-emerald mt-2.5 flex">
                                    +13.49%
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-10 mt-10">
                        <div className="w-1/2">
                            <h1 className="text-white text-17 font-semibold mb-7">Daily P&L of {exchangeAccountAssetProfile.current.baseSymbol}</h1>
                            <div className="gap-10 mt-10">
                                <div className="">
                                    <div className="flex justify-between items-center" >
                                        <div className="bg-white bg-opacity-5 rounded-sm border border-white border-opacity-10 py-[3px] px-[7px]">
                                            <div className="flex space-x-2">
                                                {['1D', '7D', '1M', '3M', '1Y', '2Y'].map((range) => (
                                                    <button
                                                        key={range}
                                                        className="text-white text-xs p-[6px] hover:bg-white hover:bg-opacity-10 hover:rounded-sm"
                                                        onClick={() => handleDateChange(range)}
                                                    >
                                                        {range}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="relative chart-datepicker flex items-center">
                                            <DatePicker
                                                selected={nagetivePositivestartDate}
                                                onChange={nagetivePositiveStartDate}
                                                className="bg-transparent text-white font-normal text-sm block focus:outline-none w-full placeholder-grey-nobel"
                                                placeholderText="Starting date"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        {tooltipInfo && (
                                            <div className="mt-6 mb-3">
                                                <p className="text-xs text-white font-normal tracking-normal focus-within">
                                                    {tooltipInfo.date}
                                                </p>
                                                <h2 className={`text-base font-semibol mt-2.5 flex ${tooltipInfo.value >= 0 ? "text-emerald" : "text-persian"} `}>
                                                    {tooltipInfo.value >= 0 ? '+' : '-'}
                                                    {Math.abs(tooltipInfo.value).toLocaleString(undefined, {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    })} USD
                                                </h2>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative w-full h-80" ref={chartNegativePositive} />
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <h1 className="text-white text-17 font-semibold mb-7">Cumulative P&L of {exchangeAccountAssetProfile.current.baseSymbol}</h1>
                            <div className="gap-10 mt-10">
                                <div className="">
                                    <div className="flex justify-between items-center" >
                                        <div className="bg-white bg-opacity-5 rounded-sm border border-white border-opacity-10 py-[3px] px-[7px]">
                                            <div className="flex space-x-2">
                                                {['2D', '5D', '4M', '2M', '8Y', '3Y'].map((range) => (
                                                    <button
                                                        key={range}
                                                        className="text-white text-xs p-[6px] hover:bg-white hover:bg-opacity-10 hover:rounded-sm"
                                                        onClick={() => handleDateChange(range)}>
                                                        {range}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="relative chart-datepicker flex items-center">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleStartDateChange}
                                                className="bg-transparent text-white font-normal text-sm block focus:outline-none w-full placeholder-grey-nobel"
                                                placeholderText="Starting date"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        {tooltipInfo && (
                                            <div className="mt-6 mb-3">
                                                <p className="text-xs text-white font-normal tracking-normal focus-within">
                                                    {tooltipInfo.date}
                                                </p>
                                                <h2 className={`text-base font-semibol mt-2.5 flex ${tooltipInfo.value >= 0 ? "text-emerald" : "text-persian"} `}>
                                                    {tooltipInfo.value >= 0 ? '+' : '-'}
                                                    {Math.abs(tooltipInfo.value).toLocaleString(undefined, {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    })} USD
                                                </h2>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative w-full h-80" ref={chartContainerRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="3xl:w-[30%] w-full 3xl:mt-0 mt-6">
                    <div className="">
                        <div className="flex items-center">
                            <p className="text-15 font-semibold text-white border-dashed border-b">
                                Overview
                            </p>
                            <div className="w-max">
                                <img
                                    src={Images.info}
                                    className="w-4 h-4 ml-2"
                                    alt="Info"
                                />
                            </div>
                        </div>
                        <div className="border-white border-opacity-20 border rounded py-2 mt-3 px-6 flex justify-between w-full xl:w-1/2 3xl:w-full bg-white bg-opacity-5">
                            <div className="min-w-[20%]">
                                <p className="text-sm font-normal text-grey-suit">Orders</p>
                                <p className="text-base font-semibold text-white mt-3">
                                    {longExchangeAccountAssetPosition.current.orderLevel + shortExchangeAccountAssetPosition.current.orderLevel}
                                </p>
                            </div>
                            <div className="min-w-[30%]">
                                <p className="text-sm font-normal text-grey-suit">
                                    Holding
                                </p>
                                <p className="text-base font-semibold text-white mt-3">
                                    ${formatNumberWithCommas(parseFloat((longExchangeAccountAssetPosition.current.quoteQuantity + shortExchangeAccountAssetPosition.current.quoteQuantity).toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                </p>
                            </div>
                            <div className="min-w-[50%]">
                                <p className="text-sm font-normal text-grey-suit">
                                    Floating
                                </p>
                                {parseFloat(unrealizedPnL.current) > 0 ? (
                                    <p className="text-base font-semibold text-emerald mt-3">
                                        ${formatNumberWithCommas(unrealizedPnL.current)} ({unrealizedPnLPercentage.current}%)
                                    </p>
                                ) : parseFloat(unrealizedPnL.current) < 0 ? (
                                    <p className="text-base font-semibold text-persian mt-3">
                                        ${formatNumberWithCommas(unrealizedPnL.current)} ({unrealizedPnLPercentage.current}%)
                                    </p>
                                ) : (
                                    <p className="text-base font-semibold text-emerald mt-3">
                                        ${formatNumberWithCommas(unrealizedPnL.current)} ({unrealizedPnLPercentage.current}%)
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {isShowLongOrders.current ? (
                        <div className="3xl:block xl:flex block xl:gap-10 gap-0">
                            <div className="border-white border-opacity-20 border mt-8 p-5 rounded bg-grey-bunker 3xl:w-full xl:w-2/4 w-full">
                                <p className="bg-emerald bg-opacity-25 text-emerald py-2.5 px-3.5 text-sm font-semibold mb-5">
                                    Long orders
                                </p>
                                <div className="grid grid-cols-3 gap-4">
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Holding ($)
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(longExchangeAccountAssetPosition.current.quoteQuantity.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Floating P & L
                                        </p>
                                        {
                                            parseFloat(longExchangeAccountAssetPosition.current.unrealizedPnL) > 0 ? (
                                                <p className="text-sm font-semibold text-emerald mt-2.5 flex items-center">
                                                    ${formatNumberWithCommas(longExchangeAccountAssetPosition.current.unrealizedPnL.toString())}
                                                    <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                        {longExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                    </span>
                                                </p>) : parseFloat(longExchangeAccountAssetPosition.current.unrealizedPnL) < 0 ? (
                                                    <p className="text-sm font-semibold text-persian mt-2.5 flex items-center">
                                                        ${formatNumberWithCommas(longExchangeAccountAssetPosition.current.unrealizedPnL.toString())}
                                                        <span className="bg-persian bg-opacity-25 text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {longExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    </p>
                                                ) : (
                                                <p className="text-sm font-semibold text-emerald mt-2.5 flex items-center">
                                                    ${formatNumberWithCommas(longExchangeAccountAssetPosition.current.unrealizedPnL.toString())}
                                                    <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                        {longExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                    </span>
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Target amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(longExchangeAccountAssetPosition.current.takeProfitPrice.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex justify-between">
                                            <p className="text-xs font-normal text-grey-suit">
                                                Order level
                                            </p>
                                            <p className="bg-gradient text-white text-xs text-center font-semibold w-7 h-5 flex items-center justify-center">{longExchangeAccountAssetPosition.current.orderLevel}</p>
                                        </div>
                                        <div>
                                            <Slider
                                                className="custom-slider order-level coin-renge mt-2 mb-4 w-[97%]"
                                                min={1}
                                                max={100}
                                                value={longAssetOrderLevelPercentage.current}
                                                step={1}
                                                dotStyle={{ display: 'none' }}
                                                activeDotStyle={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-3 gap-4">
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Average amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(longExchangeAccountAssetPosition.current.averagePrice.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Next order
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(longExchangeAccountAssetPosition.current.nextOrderPrice.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Coin setup
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            {longExchangeAccountAssetPosition.current.orderSize} <span className="font-normal">({longExchangeAccountAssetPosition.current.leverage}x)</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Holding (Coin)
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            {formatNumberWithCommas(longExchangeAccountAssetPosition.current.assetQuantity.toFixed(exchangeAccountAssetProfile.current.quantityPrecision))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div></div>)}
                    {isShowShortOrders.current ? (
                        <div className="3xl:block xl:flex block xl:gap-10 gap-0">
                            <div className="border-white border-opacity-20 border mt-8 p-5 rounded bg-grey-bunker 3xl:w-full xl:w-2/4 w-full">
                                <p className="bg-persian bg-opacity-25 text-persian py-2.5 px-3.5 text-sm font-semibold mb-5">
                                    Short orders
                                </p>
                                <div className="grid grid-cols-3 gap-4">
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Holding ($)
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(shortExchangeAccountAssetPosition.current.quoteQuantity.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Floating P & L
                                        </p>
                                        {
                                            parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnL) > 0 ? (
                                                <p className="text-sm font-semibold text-emerald mt-2.5 flex items-center">
                                                    ${formatNumberWithCommas(shortExchangeAccountAssetPosition.current.unrealizedPnL).toString()}
                                                    {parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage) > 0 ? (
                                                        <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    ) : parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage) < 0 ? (
                                                        <span className="bg-persian bg-opacity-25 text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    ) : (
                                                        <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    )}
                                                </p>) : parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnL) < 0 ? (
                                                    <p className="text-sm font-semibold text-persian mt-2.5 flex items-center">
                                                        ${formatNumberWithCommas(shortExchangeAccountAssetPosition.current.unrealizedPnL.toString())}
                                                        {parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage) > 0 ? (
                                                            <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                            </span>
                                                        ) : parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage) < 0 ? (
                                                            <span className="bg-persian bg-opacity-25 text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                            </span>
                                                        ) : (
                                                            <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                            </span>
                                                        )}
                                                    </p>
                                                ) : (
                                                <p className="text-sm font-semibold text-emerald mt-2.5 flex items-center">
                                                    ${formatNumberWithCommas(shortExchangeAccountAssetPosition.current.unrealizedPnL.toString())}
                                                    {parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage) > 0 ? (
                                                        <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    ) : parseFloat(shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage) < 0 ? (
                                                        <span className="bg-persian bg-opacity-25 text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    ) : (
                                                        <span className="bg-emerald bg-opacity-25 text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                            {shortExchangeAccountAssetPosition.current.unrealizedPnLPercentage}%
                                                        </span>
                                                    )}
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Target amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(shortExchangeAccountAssetPosition.current.takeProfitPrice.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex justify-between">
                                            <p className="text-xs font-normal text-grey-suit">
                                                Order level
                                            </p>
                                            <p className="bg-gradient text-white text-xs text-center font-semibold w-7 h-5 flex items-center justify-center">{shortExchangeAccountAssetPosition.current.orderLevel}</p>
                                        </div>
                                        <Slider
                                            className="custom-slider order-level coin-renge mt-2 mb-4 w-[97%]"
                                            min={1}
                                            max={100}
                                            value={shortAssetOrderLevelPercentage.current}
                                            step={1}
                                            dotStyle={{ display: 'none' }}
                                            activeDotStyle={{ display: 'none' }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-3 gap-4">
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Average amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(shortExchangeAccountAssetPosition.current.averagePrice.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Next order
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            ${formatNumberWithCommas(parseFloat(shortExchangeAccountAssetPosition.current.nextOrderPrice.toString()).toFixed(exchangeAccountAssetProfile.current.pricePrecision))}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Coin setup
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            {shortExchangeAccountAssetPosition.current.orderSize} <span className="font-normal">({shortExchangeAccountAssetPosition.current.leverage}x)</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Holding (Coin)
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-2.5">
                                            {formatNumberWithCommas(shortExchangeAccountAssetPosition.current.assetQuantity.toFixed(exchangeAccountAssetProfile.current.quantityPrecision))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div></div>)}
                </div>
            </div>
            <div className="mt-12">
                <div className="overflow-auto max-h-[calc(100vh-160px)]">
                    <table className="border-2 border-grey-bunker w-full">
                        <thead>
                            <tr className="bg-white bg-opacity-4">
                                <th className="p-5 pl-7 text-start text-base whitespace-nowrap font-semibold text-white tracking-0.16">Date</th>
                                <th className="p-5 text-start text-base whitespace-nowrap font-semibold text-white tracking-0.16"> <span className="border-dashed border-b">Daily P & L</span></th>
                                <th className="p-5 text-start text-base whitespace-nowrap font-semibold text-white tracking-0.16"> <span className="border-dashed border-b">Commutative P & L</span></th>
                                <th className="p-5 text-start text-base whitespace-nowrap font-semibold text-white tracking-0.16"> <span className="border-dashed border-b">Commutative P & L (%)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b-2 border-grey-bunker">
                                <td className="py-4 px-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16"> 21-09-2023 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap  font-semibold text-emerald tracking-0.16 flex items-center">
                                    $ 20
                                </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> $ 20.6 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> 25.45% </td>
                            </tr>
                            <tr className="border-b-2 border-grey-bunker">
                                <td className="py-4 px-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16"> 21-09-2023 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap  font-semibold text-emerald tracking-0.16 flex items-center">
                                    $ 20
                                </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> $ 20.6 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> 25.45% </td>
                            </tr>
                            <tr className="border-b-2 border-grey-bunker">
                                <td className="py-4 px-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16"> 21-09-2023 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap  font-semibold text-emerald tracking-0.16 flex items-center">
                                    $ 20
                                </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> $ 20.6 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> 25.45% </td>
                            </tr>
                            <tr className="border-b-2 border-grey-bunker">
                                <td className="py-4 px-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16"> 21-09-2023 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap  font-semibold text-emerald tracking-0.16 flex items-center">
                                    $ 20
                                </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> $ 20.6 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-persian tracking-0.16"> 25.45% </td>
                            </tr>
                            <tr className="border-b-2 border-grey-bunker">
                                <td className="py-4 px-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16"> 21-09-2023 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap  font-semibold text-emerald tracking-0.16 flex items-center">
                                    $ 20
                                </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> $ 20.6 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> 25.45% </td>
                            </tr>
                            <tr className="border-b-2 border-grey-bunker">
                                <td className="py-4 px-5 pl-7 text-start whitespace-nowrap  font-normal text-white tracking-0.16"> 21-09-2023 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap  font-semibold text-emerald tracking-0.16 flex items-center">
                                    $ 20
                                </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-emerald tracking-0.16"> $ 20.6 </td>
                                <td className="py-4 px-5 text-start whitespace-nowrap font-semibold text-persian tracking-0.16"> 25.45% </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center justify-end pt-5">
                    <div>
                        <nav className="inline-flex gap-2" aria-label="Pagination">
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">
                                <span className="sr-only">Previous</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M11.7266 12L12.6666 11.06L9.61329 8L12.6666 4.94L11.7266 4L7.72663 8L11.7266 12Z" fill="currentColor" />
                                    <path d="M7.33332 12L8.27332 11.06L5.21998 8L8.27331 4.94L7.33331 4L3.33332 8L7.33332 12Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">
                                <span className="sr-only">Previous</span>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <button type="button" aria-current="page" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-white p-1.5 border border-[#ffffff0f] bg-gradient">1</button>
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">2</button>
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">3</button>
                            <span className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">...</span>
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">10</button>
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">
                                <span className="sr-only">Next</span>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <button type="button" className="flex items-center justify-center text-13 w-8 h-8 rounded-full text-grey-storm p-1.5 border border-[#ffffff0f] bg-[#ffffff0f]">
                                <span className="sr-only">Next</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M4.27337 4L3.33337 4.94L6.38671 8L3.33337 11.06L4.27337 12L8.27337 8L4.27337 4Z" fill="currentColor" />
                                    <path d="M8.66668 4L7.72668 4.94L10.78 8L7.72668 11.06L8.66668 12L12.6667 8L8.66668 4Z" fill="currentColor" />
                                </svg>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoinProfile;

// function setData(barsData: { time: string; value: number; }[]) {
//     throw new Error("Function not implemented.");
// }

// function setValue(newValue: any) {
//     throw new Error("Function not implemented.");
// }

